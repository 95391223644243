import { Field } from "../../../types/field";
import { formatDate, getOpeningStockDate, getToday } from "../../../services/dateUtils";
import { translatePicklistValue } from "../../../services/fieldsUtils";
import { projectTypeValues } from "../../../standardEntities/project";
import { materialTypeValues, inTransactionTypeValues } from "../../../standardEntities/materialInward";
export const storeTypeValues = [
  { value: "CS", label: "Contractor Store" },
  { value: "PS", label: "Partner Store" },
];

export const stockReportCategoryValues = [
  { value: "ITEM_SUMMARY", label: "Stock Item Summary Report" },
  { value: "FULL_DETAIL", label: "Stock Inward Detail Report" },
];

export const getStockReportFormFields = (): Field[] => [
  {
    name: "sections",
    type: "formSection",
    childFields: [
      {
        name: "reportCategory",
        label: "Report Category",
        type: "picklist",
        defaultValue: "ITEM_SUMMARY",
        props: {
          width: "25vw",
        },
        values: stockReportCategoryValues,
      },
    ],
  },
  {
    name: "sections",
    type: "formSection",
    childFields: [
      {
        name: "docLocatorName",
        label: "Inward Locator Name",
        allowNull: true,
        props: {
          width: "25vw",
        },
        type: "lookup",
        lookupType: "locator",
        lookupFieldName: "locatorCode",
        lookupCategory: "docLocator",
        lookupParams: [
          {
            key: "locatorType",
            resolveValue: true,
            valueFieldName: "docLocatorType",
          },
          {
            key: "projectCode",
            resolveValue: true,
            valueFieldName: "projectCode",
          },
          {
            key: "docCompanyCode",
            resolveValue: true,
            valueFieldName: "docCompanyCode",
          },
        ],
        lookupFilter: {
          type: "AND",
          filters: [
            {
              type: "IN",
              field: "locatorType",
              values: ["PS", "CS"],
            },
            {
              type: "EQUALS",
              field: "locatorCode",
              resolveValue: true,
              valueFieldName: "docLocatorCode",
            },
            {
              type: "EQUALS",
              field: "locatorType",
              resolveValue: true,
              valueFieldName: "docLocatorType",
            },
            {
              type: "EQUALS",
              field: "stateCode",
              resolveValue: true,
              valueFieldName: "docStateCode",
            },
            {
              type: "EQUALS",
              field: "companyCode",
              resolveValue: true,
              valueFieldName: "docCompanyCode",
            },
          ],
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "docLocatorCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "locatorType",
            fieldName: "docLocatorType",
          },
          {
            additionalFieldName: "company",
            lookupFieldName: "companyCode",
            fieldName: "docCompanyCode",
          },
          {
            additionalFieldName: "company",
            lookupFieldName: "companyName",
            fieldName: "docCompanyName",
          },
          {
            additionalFieldName: "company",
            lookupFieldName: "companyCode",
            fieldName: "docCompanyCode",
          },
          {
            additionalFieldName: "company",
            lookupFieldName: "companyName",
            fieldName: "docCompanyName",
          },
          {
            additionalFieldName: "station",
            lookupFieldName: "stateCode",
            fieldName: "docStateCode",
          },
          {
            additionalFieldName: "station",
            lookupFieldName: "stateName",
            fieldName: "docStateName",
          },
        ],
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "docLocatorType",
        label: "Store Type",
        type: "picklist",
        defaultValue: "",
        allowNull: true,
        values: storeTypeValues,
        props: {
          width: "11vw",
        },
        disability: {
          type: "OR",
          filters: [
            {
              type: "EXISTS",
              field: "docLocatorCode",
            },
          ],
        },
      },
      {
        name: "docLocatorCode",
        label: "Locator",
        type: "text",
        allowNull: true,
        props: {
          width: "6vw",
        },
        autoFilled: true,
      },
      {
        name: "docCompanyCode",
        label: "Company",
        type: "text",
        props: {
          width: "6vw",
        },
        autoFilled: true,
        allowNull: true,
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "docCompanyName",
        label: "Company Name",
        type: "lookup",
        lookupType: "company",
        // lookupCategory: "workClosureContractor",
        lookupFilter: {
          type: "OR",
          filters: [
            {
              type: "IN",
              field: "contractorFlg",
              values: [true],
            },
            {
              type: "IN",
              field: "partnerFlg",
              values: [true],
            },
          ],
        },
        // lookupFilter: { type: "AND", filters: [{ type: "IN", field: "contractorFlg", values: [true] }] },
        props: {
          width: "25vw",
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "docCompanyCode",
          },
        ],
        disability: {
          type: "OR",
          filters: [
            {
              type: "EXISTS",
              field: "docLocatorCode",
            },
          ],
        },
        allowNull: true,
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "docStateCode",
        label: "State Code",
        type: "text",
        defaultValue: "",
        autoFilled: true,
        allowNull: true,
        props: {
          width: "7vw",
        },
      },
      {
        name: "docStateName",
        label: "State Name",
        type: "lookup",
        lookupType: "state",
        lookupCategory: "reportingStates",
        defaultValue: null,
        allowUpdate: false,
        lookupFilter: {
          type: "EQUALS",
          field: "stateCode",
          resolveValue: true,
          valueFieldName: "docStateCode",
        },
        lookupParams: [
          {
            key: "locatorCode",
            resolveValue: true,
            valueFieldName: "docLocatorCode",
          },
        ],
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "docStateCode",
          },
        ],
        props: {
          width: "17vw",
        },
        linkedFields: [
          {
            name: "projectName",
            value: "",
          },
          {
            name: "projectCode",
            value: "",
          },
          {
            name: "projectType",
            value: "",
          },
        ],
        disability: {
          type: "OR",
          filters: [
            {
              type: "EXISTS",
              field: "docLocatorCode",
            },
          ],
        },
      },
    ],
  },
  {
    name: "sections",
    type: "formSection",
    childFields: [
      {
        name: "projectName",
        label: "Project Name",
        type: "lookup",
        lookupType: "project",
        lookupCategory: "docProject",
        allowNull: true,
        props: {
          width: "25vw",
        },
        lookupFilter: {
          type: "AND",
          filters: [
            {
              type: "EQUALS",
              field: "projectCode",
              resolveValue: true,
              valueFieldName: "projectCode",
            },
            {
              type: "EQUALS",
              field: "stateCode",
              resolveValue: true,
              valueFieldName: "docStateCode",
            },
            {
              type: "EQUALS",
              field: "projectType",
              resolveValue: true,
              valueFieldName: "projectType",
            },
          ],
        },
        lookupParams: [
          {
            key: "docLocatorCode",
            resolveValue: true,
            valueFieldName: "docLocatorCode",
          },
          {
            key: "docCompanyCode",
            resolveValue: true,
            valueFieldName: "docCompanyCode",
          },
        ],
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "projectCode",
          },
          {
            lookupFieldName: "description",
            fieldName: "projectType",
          },
        ],
      },
    ],
  },
  {
    name: "sections",
    type: "formSection",
    childFields: [
      {
        name: "projectType",
        label: "Project Type",
        type: "picklist",
        defaultValue: "",
        allowNull: true,
        values: projectTypeValues,
        props: {
          width: "12vw",
        },
        disability: {
          type: "OR",
          filters: [
            {
              type: "EXISTS",
              field: "projectCode",
            },
          ],
        },
      },
      {
        name: "projectCode",
        label: "Code",
        type: "text",
        autoFilled: true,
        allowNull: true,
        defaultValue: null,
        props: {
          width: "12vw",
        },
      },
    ],
  },
  {
    name: "sections",
    type: "formSection",
    childFields: [
      {
        name: "itemName",
        label: "Item Name",
        type: "lookup",
        lookupType: "item",
        lookupFieldName: "itemName",
        allowNull: true,
        props: {
          width: "25vw",
        },
        lookupFilter: {
          type: "AND",
          filters: [
            {
              type: "EQUALS",
              field: "itemCode",
              resolveValue: true,
              valueFieldName: "itemCode",
            },
            {
              type: "EQUALS",
              field: "itemGroupId",
              resolveValue: true,
              valueFieldName: "itemGroupId",
            },
          ],
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "itemCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "itemCustCode",
            fieldName: "itemCustCode",
          },
        ],
      },
    ],
  },
  {
    name: "sections",
    type: "formSection",
    childFields: [
      {
        name: "materialType",
        label: "Material Type",
        type: "picklist",
        allowNull: true,
        values: materialTypeValues,
        props: {
          width: "12vw",
        },
      },
      {
        name: "itemCustCode",
        label: "Part Code",
        type: "text",
        allowNull: true,
        autoFilled: true,
        props: {
          width: "12vw",
        },
      },
    ],
  },
  {
    name: "sections",
    type: "formSection",
    childFields: [
      {
        name: "fromDocDate",
        label: "Inward From Date",
        allowNull: true,
        type: "date",
        defaultValue: getOpeningStockDate(),
        constraints: [
          {
            type: "DATE_RANGE",
            minDate: getOpeningStockDate(),
            maxDateScript: {
              operation: "minDate",
              valueFields: ["toDocDate", "stockDate"],
            },
            beforeNow: true,
          },
        ],
      },
      {
        name: "toDocDate",
        label: "Inward To Date",
        allowNull: true,
        type: "date",
        defaultValue: getToday(),
        constraints: [
          {
            type: "DATE_RANGE",
            minDateScript: {
              operation: "minDate",
              values: [getOpeningStockDate()],
              valueFields: ["fromDocDate"],
            },
            maxDateScript: {
              valueFields: ["stockDate"],
            },
            beforeNow: true,
          },
        ],
      },
    ],
  },
  {
    name: "sections",
    type: "formSection",
    childFields: [
      {
        name: "stockDate",
        label: "Stock as on date",
        type: "date",
        defaultValue: getToday(),
        constraints: [
          {
            type: "DATE_RANGE",
            minDateScript: {
              operation: "minDate",
              values: [getOpeningStockDate()],
              valueFields: ["toDocDate"],
            },
            maxDateScript: {
              valueFields: ["today"],
            },
            beforeNow: true,
          },
        ],
      },
    ],
  },
];

export const stockItemSummaryExcelColumns = [
  {
    name: "docLocatorCode",
    label: "Locator Code",
    type: "text",
  },
  {
    name: "locatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "docStationName",
    label: "Station Name",
    type: "text",
  },
  {
    name: "docCompanyCode",
    label: "Company Code",
    type: "text",
  },
  {
    name: "docCompanyName",
    label: "Company Name",
    type: "text",
  },
  {
    name: "projectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "virtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "stateName",
    label: "State",
    type: "text",
  },
  { name: "ownerCode", label: "Owner Code", type: "text" },
  { name: "ownerName", label: "Owner Name", type: "text" },
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
  },
  {
    name: "itemId",
    label: "Item Id",
    type: "text",
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "uom",
    label: "UOM",
    type: "text",
  },
  {
    name: "totalAvailableQuantity",
    label: "Available Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalAvailableAmount",
    label: "Available Amt",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalPhysicalQuantity",
    label: "Physical Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalPhysicalAmount",
    label: "Physical Amt",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
];

// export const stockInwardSummaryExcelColumns = [
//   {
//     name: "docLocatorCode",
//     label: "Locator Code",
//     type: "text",
//   },
//   {
//     name: "locatorName",
//     label: "Locator Name",
//     type: "text",
//   },
//   {
//     name: "docStationName",
//     label: "Station Name",
//     type: "text",
//   },
//   {
//     name: "companyCode",
//     label: "Company Code",
//     type: "text",
//   },
//   {
//     name: "companyName",
//     label: "Company Name",
//     type: "text",
//   },
//   {
//     name: "projectCode",
//     label: "Project",
//     type: "text",
//   },
//   {
//     name: "projectName",
//     label: "Project Name",
//     type: "text",
//   },
//   {
//     name: "projectType",
//     label: "Project Type",
//     type: "text",
//     format: (value) => translatePicklistValue(value, projectTypeValues),
//   },
//   {
//     name: "virtualLocator",
//     label: "Virtual Locator",
//     type: "text",
//   },
//   {
//     name: "stateName",
//     label: "State",
//     type: "text",
//   },
//   {
//     name: "mirId",
//     label: "MIR No",
//     type: "text",
//   },
//   {
//     name: "docDate",
//     label: "MIR Date",
//     type: "date",
//     format: (value) => formatDate(value),
//   },
//   {
//     name: "transactionType",
//     label: "Transaction",
//     type: "text",
//     format: (value) => inTransactionTypeValues.find((item) => item.value === value).label,
//   },
//   {
//     name: "materialType",
//     label: "Mat Type",
//     type: "text",
//     format: (value) => materialTypeValues.find((item) => item.value === value).label,
//   },
//   {
//     name: "senderCompanyCode",
//     label: "Sender Company",
//     type: "text",
//   },
//   {
//     name: "senderCompanyName",
//     label: "Sender Company Name",
//     type: "text",
//   },
//   {
//     name: "senderLocatorCode",
//     label: "Sender Locator",
//     type: "text",
//   },
//   {
//     name: "senderLocatorName",
//     label: "Sender Locator Name",
//     type: "text",
//   },
//   {
//     name: "senderStationName",
//     label: "Sender Station",
//     type: "text",
//   },
//   {
//     name: "senderStateName",
//     label: "State/Country",
//     type: "text",
//   },
//   {
//     name: "basicValue",
//     label: "Basic Value",
//     type: "text",
//     format: (value) => (value ? parseFloat(value) : 0),
//   },
//   {
//     name: "inventoryValue",
//     label: "Inventory Value",
//     type: "text",
//     format: (value) => (value ? parseFloat(value) : 0),
//   },
//   {
//     name: "submit",
//     label: "Submit",
//     type: "text",
//     format: (value) => (value ? "True" : "False"),
//   },
//   {
//     name: "itemName",
//     label: "Item Name",
//     type: "text",
//   },
//   {
//     name: "itemId",
//     label: "Item Id",
//     type: "text",
//   },
//   {
//     name: "itemCustCode",
//     label: "Part Code",
//     type: "text",
//   },
//   {
//     name: "uom",
//     label: "UOM",
//     type: "text",
//   },
//   {
//     name: "inwardQuantity",
//     label: "Inward Quantity",
//     type: "text",
//     format: (value) => (value ? parseFloat(value) : 0),
//   },
//   {
//     name: "inwardAmount",
//     label: "Inward Amount",
//     type: "text",
//     format: (value) => (value ? parseFloat(value) : 0),
//   },
//   {
//     name: "totalAvailableQuantity",
//     label: "Available Qty",
//     type: "text",
//     format: (value) => (value ? parseFloat(value) : 0),
//   },
//   {
//     name: "totalAvailableAmount",
//     label: "Available Amt",
//     type: "text",
//     format: (value) => (value ? parseFloat(value) : 0),
//   },
//   {
//     name: "totalPhysicalQuantity",
//     label: "Physical Qty",
//     type: "text",
//     format: (value) => (value ? parseFloat(value) : 0),
//   },
//   {
//     name: "totalPhysicalAmount",
//     label: "Physical Amt",
//     type: "text",
//     format: (value) => (value ? parseFloat(value) : 0),
//   },
// ];

export const stockFullDetailExcelColumns = [
  {
    name: "docLocatorCode",
    label: "Locator Code",
    type: "text",
  },
  {
    name: "locatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "docStationName",
    label: "Station Name",
    type: "text",
  },
  { name: "ownerCode", label: "Owner Code", type: "text" },
  { name: "ownerName", label: "Owner Name", type: "text" },
  {
    name: "projectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "virtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "stateName",
    label: "State",
    type: "text",
  },
  {
    name: "mirId",
    label: "MIR No",
    type: "text",
  },
  {
    name: "docDate",
    label: "MIR Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "transactionType",
    label: "Transaction",
    type: "text",
    format: (value) => inTransactionTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "materialType",
    label: "Mat Type",
    type: "text",
    format: (value) => materialTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "submit",
    label: "Submit",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "mirRowId",
    label: "MIR Row Id",
    type: "text",
  },
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
  },
  {
    name: "itemId",
    label: "Item Id",
    type: "text",
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "uom",
    label: "UOM",
    type: "text",
  },
  {
    name: "inwardQuantity",
    label: "Inward Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "inwardAmount",
    label: "Inward Amt",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalAvailableQuantity",
    label: "Available Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalAvailableAmount",
    label: "Available Amt",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalPhysicalQuantity",
    label: "Physical Qty",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalPhysicalAmount",
    label: "Physical Amt",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
];
