import { Field } from "../types/field";
import { translatePicklistValue } from "../services/fieldsUtils";

export const projectTypeValues = [
  { value: "FF", label: "FF Work" },
  { value: "FTTH", label: "FTTH Work" },
];

export const jobTypeValues = [
  { value: "BBW", label: "Back Bone" },
  { value: "LMW", label: "Last Mile" },
  { value: "ICW", label: "Inter City" },
  { value: "FTTB", label: "FTTB" },
  { value: "OTH", label: "Others" },
];

export const jobFields: Field[] = [
  {
    name: "projectName",
    label: "Project Name",
    type: "lookup",
    lookupType: "project",
    lookupCategory: "workProjects",
    lookupFilter: {
      type: "AND",
      filters: [
        {
          type: "EQUALS",
          field: "stateCode",
          resolveValue: true,
          valueFieldName: "locatorStateCode",
        },
        {
          type: "IN",
          field: "projectType",
          values: ["FF"],
        },
      ],
    },
    dependentFields: [
      {
        lookupFieldName: "id",
        fieldName: "projectCode",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "stateCode",
        fieldName: "stateCode",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "stateName",
        fieldName: "stateName",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "projectType",
        fieldName: "projectType",
      },
    ],
    linkedFields: [
      {
        name: "locatorName",
        value: "",
      },
      {
        name: "locatorCode",
        value: "",
      },
      {
        name: "companyName",
        value: "",
      },
      {
        name: "companyCode",
        value: "",
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "projectType",
        label: "Project Type",
        type: "picklist",
        defaultValue: "",
        autoFilled: true,
        values: [
          { value: "FF", label: "FF Work" },
          { value: "FTTH", label: "FTTH Work" },
        ],
        props: {
          width: "17vw",
        },
      },
      {
        name: "projectCode",
        label: "Project Code",
        type: "text",
        autoFilled: true,
        props: {
          width: "7vw",
        },
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "jobType",
        label: "Job Type",
        type: "picklist",
        allowUpdate: true,
        defaultValue: "",
        values: jobTypeValues,
      },
      {
        name: "stateName",
        label: "State Name",
        type: "text",
        autoFilled: true,
        allowNull: true,
      },
    ],
  },
  {
    name: "siteId",
    label: "Site Id",
    type: "text",
    defaultValue: "",
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "jobId",
        label: "Job Id",
        type: "number",
        // constraints: [{ type: "RANGE", minLimit: 0 }],
        allowNull: true,
        allowUpdate: true,
      },
      {
        name: "poNo",
        label: "PO number",
        type: "number",
        allowUpdate: true,
        defaultValue: "",
      },
    ],
  },
  {
    name: "sections",
    type: "formSection",
    childFields: [
      {
        name: "locatorName",
        label: "Locator Name",
        props: {
          width: "17vw",
        },
        type: "lookup",
        lookupType: "locator",
        lookupFieldName: "locatorCode",
        lookupFilter: {
          type: "AND",
          filters: [
            {
              type: "IN",
              field: "locatorType",
              values: ["CS"],
            },
            {
              type: "EQUALS",
              field: "locatorCode",
              resolveValue: true,
              valueFieldName: "locatorCode",
            },
            {
              type: "EQUALS",
              field: "stateCode",
              resolveValue: true,
              valueFieldName: "stateCode",
            },
          ],
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "locatorCode",
          },
          {
            additionalFieldName: "company",
            lookupFieldName: "companyCode",
            fieldName: "companyCode",
          },
          {
            additionalFieldName: "company",
            lookupFieldName: "companyName",
            fieldName: "companyName",
          },
        ],
      },
      {
        name: "locatorCode",
        label: "Locator",
        type: "text",
        props: {
          width: "7vw",
        },
        autoFilled: true,
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "companyCode",
        label: "Company",
        type: "text",
        props: {
          width: "7vw",
        },
        autoFilled: true,
        allowNull: true,
      },
      {
        name: "companyName",
        label: "Company Name",
        type: "text",
        props: {
          width: "17vw",
        },
        autoFilled: true,
        allowNull: true,
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "remarks",
        label: "Remarks",
        type: "text",
        allowUpdate: true,
        allowNull: true,
        props: {
          width: "17vw",
        },
      },
      {
        name: "id",
        label: "Id",
        type: "number",
        props: {
          width: "7vw",
        },
        autoFilled: true,
        allowNull: true,
      },
    ],
  },
  {
    name: "section4",
    type: "formSection",
    childFields: [
      {
        name: "active",
        label: "Active",
        type: "checkbox",
        allowUpdate: true,
        defaultValue: false,
      },
      {
        name: "closed",
        label: "Closed",
        type: "checkbox",
        defaultValue: false,
        allowUpdate: false,
        displayOnly: true,
      },
    ],
  },
];

export const jobColumns = [
  {
    name: "jobId",
    label: "Job Id   ",
    type: "text",
  },
  {
    name: "siteId",
    label: "Site Id",
    type: "text",
  },
  {
    name: "jobType",
    label: "Job Type",
    type: "text",
    format: (value) => translatePicklistValue(value, jobTypeValues),
  },
  // {
  //   name: "poNo",
  //   label: "PO Number",
  //   type: "text",
  // },
  {
    name: "projectCode",
    label: "Code",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "locatorCode",
    label: "Locator",
    type: "text",
  },
  {
    name: "locatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "companyName",
    label: "Company Name",
    type: "text",
  },
  {
    name: "id",
    label: "Id   ",
    type: "text",
  },
  {
    name: "active",
    label: "Active",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "closed",
    label: "Closed",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

export const jobExcelColumns = [
  {
    name: "jobId",
    label: "Job Id",
    type: "text",
  },
  {
    name: "siteId",
    label: "Site Id",
    type: "text",
  },
  {
    name: "jobType",
    label: "Job Type",
    type: "text",
    format: (value) => translatePicklistValue(value, jobTypeValues),
  },
  {
    name: "poNo",
    label: "PO Number",
    type: "text",
  },
  {
    name: "projectCode",
    label: "Project Code",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "virtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "routeLength",
    label: "Project Route (Mtr)",
    type: "number",
  },
  {
    name: "locatorCode",
    label: "Locator Code",
    type: "text",
  },
  {
    name: "locatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "companyCode",
    label: "Company Code",
    type: "text",
  },
  {
    name: "companyName",
    label: "Company Name",
    type: "text",
  },
  {
    name: "id",
    label: "Id",
    type: "text",
  },
  {
    name: "remarks",
    label: "Remarks",
    type: "text",
  },
  {
    name: "active",
    label: "Active",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "closed",
    label: "Closed",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

export const jobSearchColumns = [
  {
    name: "jobId",
    label: "Job Id",
    type: "text",
  },
  {
    name: "siteId",
    label: "Site Id",
    type: "text",
  },
  {
    name: "jobType",
    label: "Job Type",
    type: "picklist",
    defaultValue: "",
    values: jobTypeValues,
  },
  {
    name: "job.projectCode",
    label: "Code",
    type: "text",
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "stateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "job.locatorCode",
    label: "Locator",
    type: "text",
  },
  // {
  //   name: "locatorName",
  //   label: "Locator Name",
  //   type: "text",
  // },
  {
    name: "companyName",
    label: "Company",
    type: "text",
  },
  // {
  //   name: "jobCode",
  //   label: "Id",
  //   type: "text",
  //   props: {
  //     widthFactor: 0.6,
  //   },
  // },
  {
    name: "active",
    label: "Active",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.6,
    },
  },
  {
    name: "closed",
    label: "Closed",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.6,
    },
  },
];
