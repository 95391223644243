import { formatDate } from "../services/dateUtils";
import { Field } from "../types/field";
import { translatePicklistValue } from "../services/fieldsUtils";
import { projectTypeValues } from "./project";
import { materialTypeValues } from "./materialInward";
import { cwoTypeValues } from "./childWorkOrder";
import { jobTypeValues } from "./job";
import { locatorTypeValues } from "../standardEntities/locator";

export const workTypeValues = [
  { value: "BBW", label: "Back Bone" },
  { value: "LMW", label: "Last Mile" },
  { value: "ICW", label: "Inter City" },
  { value: "FTTB", label: "FTTB" },
  { value: "OVH", label: "Over Head" },
  { value: "UGD", label: "Under Ground" },
  { value: "MIX", label: "Mixed" },
  { value: "IBD", label: "IBD" },
  { value: "TPT", label: "TPT" },
  { value: "RHB", label: "Rehab" },
  { value: "DHK", label: "De-Choking" },
  { value: "CTV", label: "Cut Over" },
  { value: "OTH", label: "Others" },
];

export const referenceDocTypeValues = [{ value: "NA", label: "N/A" }];

export const cbSheetDetailFields: Field[] = [
  {
    name: "sno",
    label: "SNo.",
    type: "text",
    autoFilled: true,
    allowNull: true,
    props: {
      widthFactor: 0.5,
    },
  },
  {
    name: "itemName",
    label: "Item Name",
    type: "lookup",
    lookupType: "item",
    lookupFieldName: "itemName",
    lookupCategory: "cbSheetItem",
    lookupFilter: {
      type: "AND",
      filters: [
        {
          field: "id",
          type: "NIN",
          resolveValue: true,
          isGlobalConstant: true,
          valueFieldName: "itemIds",
        },
      ],
    },
    lookupParams: [
      {
        key: "docLocatorCode",
        resolveValue: true,
        valueFieldName: "docLocatorCode",
        isGlobalConstant: true,
      },
      {
        key: "materialType",
        resolveValue: true,
        valueFieldName: "materialType",
        isGlobalConstant: true,
      },
      {
        key: "projectCode",
        resolveValue: true,
        valueFieldName: "projectCode",
        isGlobalConstant: true,
      },
      {
        key: "cbSheetDocDate",
        resolveValue: true,
        valueFieldName: "docDate",
        isGlobalConstant: true,
      },
    ],
    dependentFields: [
      {
        lookupFieldName: "id",
        fieldName: "itemCustCode",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "itemCode",
        fieldName: "itemCode",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "id",
        fieldName: "itemId",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "uom",
        fieldName: "uom",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "decimalFlg",
        fieldName: "decimalFlg",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "availableQuantity",
        fieldName: "availableQuantity",
      },
    ],
    props: {
      widthFactor: 3,
    },
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
    allowNull: true,
    autoFilled: true,
    props: {
      widthFactor: 1.6,
    },
  },
  {
    name: "uom",
    label: "Unit",
    type: "text",
    autoFilled: true,
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "availableQuantity",
    label: "Available Quantity",
    type: "text",
    allowNull: true,
    autoFilled: true,
  },
  {
    name: "totalQuantity",
    label: "Used Quantity",
    type: "number",
    defaultValue: 0,
    decimalFlg: true,
    constraints: [{ type: "RANGE", maxValueScript: { valueFields: ["availableQuantity"] }, minLimit: 0 }],
  },
  {
    name: "rate",
    label: "Rate",
    type: "number",
    decimal: true,
    autoFilled: true,
    allowNull: true,
  },
  {
    name: "totalAmount",
    label: "Total Amount",
    type: "number",
    decimal: true,
    autoFilled: true,
    valueScript: { operation: "multi", valueFields: ["rate", "totalQuantity"] },
    props: {
      widthFactor: 1,
    },
  },
  {
    name: "id",
    label: "Row Id",
    type: "text",
    allowNull: true,
    autoFilled: true,
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "itemId",
    label: "Item Id",
    type: "text",
    autoFilled: true,
    props: {
      widthFactor: 0.8,
    },
  },
];

export const cbSheetFields: Field[] = [
  {
    name: "sectionA",
    type: "formSection",
    alignment: "row",
    props: {
      width: "25vw",
      spacing: "2vw",
    },
    childFields: [
      {
        name: "section1",
        type: "formSection",
        alignment: "column",
        props: {
          width: "25vw",
          spacing: "0.75vw",
        },
        childFields: [
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "docLocatorName",
                label: "Sender Locator Name",
                props: {
                  width: "17vw",
                },
                type: "lookup",
                lookupType: "locator",
                lookupFieldName: "locatorCode",
                lookupCategory: "docLocator",
                lookupParams: [
                  {
                    key: "projectCode",
                    resolveValue: true,
                    valueFieldName: "projectCode",
                  },
                  {
                    key: "docCompanyCode",
                    resolveValue: true,
                    valueFieldName: "docCompanyCode",
                  },
                ],
                lookupFilter: {
                  type: "AND",
                  filters: [
                    {
                      type: "IN",
                      field: "locatorType",
                      values: ["CS"],
                    },
                    {
                      type: "EQUALS",
                      field: "locatorCode",
                      resolveValue: true,
                      valueFieldName: "docLocatorCode",
                    },
                  ],
                },
                dependentFields: [
                  {
                    lookupFieldName: "id",
                    fieldName: "docLocatorCode",
                  },
                  {
                    additionalFieldName: "company",
                    lookupFieldName: "companyCode",
                    fieldName: "docCompanyCode",
                  },
                  {
                    additionalFieldName: "company",
                    lookupFieldName: "companyName",
                    fieldName: "docCompanyName",
                  },
                  {
                    lookupFieldName: "description",
                    fieldName: "docStationCode",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "locatorType",
                    fieldName: "docLocatorType",
                  },
                  {
                    additionalFieldName: "station",
                    lookupFieldName: "stationName",
                    fieldName: "docStationName",
                  },
                  {
                    additionalFieldName: "station",
                    lookupFieldName: "stateName",
                    fieldName: "docStateName",
                  },
                  {
                    additionalFieldName: "station",
                    lookupFieldName: "stateCode",
                    fieldName: "stateCode",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "address1",
                    fieldName: "docAddress1",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "address2",
                    fieldName: "docAddress2",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "gstNo",
                    fieldName: "docGSTNo",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "todayDate",
                    fieldName: "docDate",
                  },
                ],
                linkedFields: [
                  {
                    name: "projectName",
                    value: "",
                  },
                  {
                    name: "projectCode",
                    value: "",
                  },
                ],
                disability: {
                  type: "EXISTS",
                  field: "details",
                },
              },
              {
                name: "docLocatorCode",
                label: "Code",
                type: "text",
                props: {
                  width: "7vw",
                },
                autoFilled: true,
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "docCompanyCode",
                label: "Code",
                type: "text",
                autoFilled: true,
                defaultValue: null,
                props: {
                  width: "7vw",
                },
              },
              {
                name: "docCompanyName",
                label: "Sender Company Name",
                type: "text",
                autoFilled: true,
                displayOnly: true,
                props: {
                  width: "17vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "docStationName",
                label: "Sender Station",
                type: "text",
                defaultValue: "",
                autoFilled: true,
                props: {
                  width: "12vw",
                },
              },
              {
                name: "docStateName",
                label: "Sender State",
                type: "text",
                defaultValue: "",
                autoFilled: true,
                props: {
                  width: "12vw",
                },
              },
            ],
          },
          {
            name: "section1",
            type: "formSection",
            childFields: [
              {
                name: "docLocatorType",
                label: "Locator Type",
                type: "picklist",
                props: {
                  width: "12vw",
                },
                values: locatorTypeValues,
                autoFilled: true,
                allowNull: true,
                defaultValue: null,
              },
              {
                name: "materialType",
                label: "Material Type",
                type: "picklist",
                defaultValue: "F",
                values: materialTypeValues,
                props: {
                  width: "12vw",
                },
                disability: {
                  type: "EXISTS",
                  field: "details",
                },
              },
            ],
          },

        ],
      },
      {
        name: "section2",
        type: "formSection",
        alignment: "column",
        props: {
          width: "21vw",
          spacing: "0.75vw",
          justify: "top",
        },
        childFields: [
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "projectType",
                label: "Project Type",
                type: "picklist",
                defaultValue: "FF",
                values: [
                  { value: "FF", label: "FF Work" },
                  { value: "FTTH", label: "FTTH Work" },
                ],
                props: {
                  width: "10vw",
                },
                linkedFields: [
                  {
                    name: "projectName",
                    value: "",
                  },
                  {
                    name: "projectCode",
                    value: "",
                  },
                  {
                    name: "jobId",
                    value: "",
                  },
                  {
                    name: "cwoId",
                    value: "",
                  },
                ],
                disability: {
                  type: "EXISTS",
                  field: "details",
                },
              },
              {
                name: "docOwnerCode",
                label: "Project Owner",
                type: "text",
                autoFilled: true,
                allowNull: true,
                defaultValue: null,
                props: {
                  width: "10vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "projectName",
                label: "Project Name",
                type: "lookup",
                lookupType: "project",
                lookupCategory: "outwardProject",
                props: {
                  width: "21vw",
                },
                lookupFilter: {
                  type: "AND",
                  filters: [
                    {
                      type: "EQUALS",
                      field: "projectCode",
                      resolveValue: true,
                      valueFieldName: "projectCode",
                    },
                    {
                      type: "EQUALS",
                      field: "projectType",
                      resolveValue: true,
                      valueFieldName: "projectType",
                    },
                    {
                      type: "EQUALS",
                      field: "projectCode",
                      resolveValue: true,
                      valueFieldName: "projectCode",
                    },
                    {
                      type: "EQUALS",
                      field: "stateCode",
                      resolveValue: true,
                      valueFieldName: "stateCode",
                    },
                  ],
                },
                lookupParams: [
                  {
                    key: "docLocatorCode",
                    resolveValue: true,
                    valueFieldName: "docLocatorCode",
                  },
                  {
                    key: "docCompanyCode",
                    resolveValue: true,
                    valueFieldName: "docCompanyCode",
                  },
                  {
                    key: "projectType",
                    resolveValue: true,
                    valueFieldName: "projectType",
                  },
                  {
                    key: "docStateCode",
                    resolveValue: true,
                    valueFieldName: "stateCode",
                  },
                ],
                dependentFields: [
                  {
                    lookupFieldName: "id",
                    fieldName: "projectCode",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "virtualLocator",
                    fieldName: "virtualLocator",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "routeLength",
                    fieldName: "routeLength",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "closed",
                    fieldName: "projectClosed",
                  },
                  { 
                    additionalFieldName: "details", 
                    lookupFieldName: "ownerCode", 
                    fieldName: "docOwnerCode" 
                  },
                ],
                warningMessageConfig: {
                  filter: { type: "IN", field: "projectClosed", values: [true] },
                  message: "Project is closed",
                },
                linkedFields: [
                  {
                    name: "jobId",
                    value: "",
                  },
                  {
                    name: "cwoId",
                    value: "",
                  },
                  {
                    name: "invoiceMBNo",
                    value: "",
                  },
                  {
                    name: "invoiceMBDate",
                    value: "",
                  },
                ],
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "EXISTS",
                      field: "details",
                    },
                    {
                      type: "MISSING",
                      field: "docLocatorCode",
                    },
                  ],
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "projectCode",
                label: "Project",
                type: "text",
                autoFilled: true,
                defaultValue: null,
                props: {
                  width: "5vw",
                },
              },
              {
                name: "virtualLocator",
                label: "Virtual Locator",
                type: "text",
                autoFilled: true,
                allowUpdate: false,
                props: {
                  width: "15vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "id",
                label: "CBS No",
                type: "text",
                displayOnly: true,
                allowNull: true,
                props: {
                  width: "10vw",
                },
              },
              {
                name: "docDate",
                label: "CBS Date",
                type: "date",
                displayOnly: true,
                props: {
                  width: "10vw",
                },
              },
            ],
          },
        ],
      },
      {
        name: "section3",
        type: "formSection",
        alignment: "column",
        props: {
          width: "25vw",
          spacing: "0.75vw",
          justify: "top",
        },
        visibility: {
          type: "IN",
          field: "projectType",
          values: ["FTTH"],
        },
        childFields: [
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "cwoId",
                label: "CWO Id",
                type: "lookup",
                lookupType: "child-work-order",
                lookupCategory: "inwardChildWorkOrder",
                allowUpdate: false,
                lookupFilter: {
                  type: "AND",
                  filters: [
                    {
                      type: "IN",
                      field: "stateCode",
                      resolveValue: true,
                      valueFieldName: "stateCode",
                    },
                    {
                      type: "IN",
                      field: "projectCode",
                      resolveValue: true,
                      valueFieldName: "projectCode",
                    },
                  ],
                },
                dependentFields: [
                  {
                    lookupFieldName: "id",
                    fieldName: "cwoNo",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "cwoDate",
                    fieldName: "cwoDate",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "cwoType",
                    fieldName: "cwoType",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "cwoType",
                    fieldName: "workType",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "workArea",
                    fieldName: "workArea",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "homePass",
                    fieldName: "homePass",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "routeLength",
                    fieldName: "routeLength",
                  },
                ],
                props: {
                  width: "12vw",
                },
                disability: {
                  type: "OR",
                  filters: [
                    { type: "EXISTS", field: "details" },
                    { type: "MISSING", field: "docLocatorCode" },
                    { type: "MISSING", field: "stateCode" },
                    { type: "MISSING", field: "projectCode" },
                  ],
                },
              },
              {
                name: "cwoDate",
                label: "CWO Date",
                type: "text",
                displayOnly: true,
                allowUpdate: true,
                defaultValue: "",
                props: {
                  width: "12vw",
                },
              },
            ],
          },
          // {
          //   name: "section1",
          //   type: "formSection",
          //   childFields: [
          //     {
          //       name: "workArea",
          //       label: "Work Area",
          //       type: "text",
          //       displayOnly: true,
          //       defaultValue: "",
          //       props: {
          //         width: "12vw",
          //       },
          //     },
          //     {
          //       name: "cwoType",
          //       label: "CWO Type",
          //       type: "picklist",
          //       autoFilled: true,
          //       values: cwoTypeValues,
          //       props: {
          //         width: "12vw",
          //       },
          //     },
          //   ],
          // },
          {
            name: "cwoNo",
            label: "CWO No",
            type: "text",
            displayOnly: true,
          },
          {
            name: "section1",
            type: "formSection",
            childFields: [
              {
                name: "homePass",
                label: "Home Pass",
                type: "number",
                displayOnly: true,
                defaultValue: "",
                props: {
                  width: "12vw",
                },
              },
              {
                name: "routeLength",
                label: "Route Length",
                type: "number",
                displayOnly: true,
                defaultValue: "",
                props: {
                  width: "12vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "invoiceMBNo",
                label: "Invoice/MB No.",
                type: "text",
                allowUpdate: true,
                defaultValue: null,
                props: {
                  width: "12vw",
                },
              },
              {
                name: "invoiceMBDate",
                label: "Invoice/MB Date",
                type: "date",
                constraints: [{ type: "DATE_RANGE", beforeNow: true }],
                allowUpdate: true,
                defaultValue: null,
                props: {
                  width: "12vw",
                },
                disability: {
                  type: "IN",
                  field: "senderDocType",
                  values: ["NA"],
                },
              },
            ],
          },
        ],
      },
      {
        name: "section3",
        type: "formSection",
        alignment: "column",
        props: {
          width: "25vw",
          spacing: "0.75vw",
          justify: "top",
        },
        visibility: {
          type: "NIN",
          field: "projectType",
          values: ["FTTH"],
        },
        childFields: [
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "jobId",
                label: "Job Id",
                type: "lookup",
                lookupType: "job",
                allowUpdate: false,
                lookupFilter: {
                  type: "AND",
                  filters: [
                    {
                      type: "IN",
                      field: "stateCode",
                      resolveValue: true,
                      valueFieldName: "stateCode",
                    },
                    {
                      type: "IN",
                      field: "projectCode",
                      resolveValue: true,
                      valueFieldName: "projectCode",
                    },
                  ],
                },
                dependentFields: [
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "siteId",
                    fieldName: "siteId",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "jobType",
                    fieldName: "jobType",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "jobType",
                    fieldName: "workType",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "poNo",
                    fieldName: "poNo",
                  },
                ],
                props: {
                  width: "12vw",
                },
                disability: {
                  type: "OR",
                  filters: [
                    { type: "EXISTS", field: "details" },
                    { type: "MISSING", field: "docLocatorCode" },
                    { type: "MISSING", field: "stateCode" },
                    { type: "MISSING", field: "projectCode" },
                  ],
                },
              },
              {
                name: "jobType",
                label: "Job Type",
                type: "picklist",
                autoFilled: true,
                values: jobTypeValues,
                props: {
                  width: "12vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "siteId",
                label: "Site Id",
                type: "text",
                displayOnly: true,
                props: {
                  width: "25vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "poNo",
                label: "PO number",
                type: "number",
                displayOnly: true,
                props: {
                  width: "12vw",
                },
              },
              {
                name: "routeLength",
                label: "Route Length",
                type: "number",
                displayOnly: true,
                props: {
                  width: "12vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "invoiceMBNo",
                label: "Invoice/MB No",
                type: "text",
                allowUpdate: true,
                defaultValue: null,
                props: {
                  width: "12vw",
                },
              },
              {
                name: "invoiceMBDate",
                label: "Invoice/MB Date",
                type: "date",
                constraints: [{ type: "DATE_RANGE", beforeNow: true }],
                allowUpdate: true,
                defaultValue: null,
                props: {
                  width: "12vw",
                },
                disability: {
                  type: "IN",
                  field: "senderDocType",
                  values: ["NA"],
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "details",
    label: "Details",
    allowUpdate: true,
    allowNull: true,
    type: "formTable",
    tableFields: cbSheetDetailFields,
    disability: {
      type: "OR",
      filters: [
        {
          type: "MISSING",
          field: "projectCode",
        },
        {
          type: "MISSING",
          field: "docLocatorName",
        },
        {
          type: "MISSING",
          field: "materialType",
        },
        {
          type: "MISSING",
          field: "workType",
        },
      ],
    },
    props: {
      width: "82vw",
    },
  },
  {
    name: "sectionB",
    type: "formSection",
    alignment: "row",
    props: {
      width: "25vw",
      spacing: "2vw",
      justify: "start",
    },
    childFields: [
      {
        name: "section1",
        type: "formSection",
        alignment: "column",
        props: {
          width: "25vw",
          spacing: "0.75vw",
          justify: "start",
        },
        childFields: [
          {
            name: "remarks",
            label: "Remarks",
            type: "text",
            allowUpdate: true,
            allowNull: true,
            props: {
              width: "25vw",
            },
          },
          {
            name: "sections",
            type: "formSection",
            props: {
              width: "25vw",
              justify: "start",
            },
            childFields: [
              {
                name: "closureId",
                label: "Closure No",
                type: "text",
                allowNull: true,
                allowUpdate: false,
                autoFilled: true,
                props: {
                  width: "12vw",
                },
              },
              {
                name: "closureDate",
                label: "Closure Date",
                type: "text",
                allowNull: true,
                allowUpdate: true,
                autoFilled: true,
                props: {
                  width: "12vw",
                },
              },
            ],
          },
        ],
      },
      {
        name: "section2",
        type: "formSection",
        alignment: "column",
        props: {
          width: "21vw",
          spacing: "0.75vw",
          justify: "top",
        },
        childFields: [
          {
            name: "sections",
            type: "formSection",
            props: {
              justify: "right",
            },
            childFields: [
              {
                name: "closed",
                label: "Closed CWO/Job",
                type: "checkbox",
                props: {
                  width: "10vw",
                },
                defaultValue: false,
                allowUpdate: false,
                autoFilled: true,
              },
              {
                name: "totalValue",
                label: "Total Value",
                autoFilled: true,
                type: "number",
                decimal: true,
                defaultValue: 0,
                props: {
                  width: "7vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            props: {
              width: "25vw",
              justify: "center",
            },
            childFields: [
              {
                name: "cancelled",
                label: "Cancelled",
                type: "checkbox",
                props: {
                  width: "25vw",
                  justify: "center",
                },
                defaultValue: false,
                allowUpdate: true,
                disabledForCreation: true,
                disability: {
                  type: "IN",
                  field: "submit",
                  values: [true],
                },
              },
              {
                name: "submit",
                label: "Submit",
                type: "checkbox",
                props: {
                  width: "25vw",
                  justify: "center",
                },
                defaultValue: false,
                allowUpdate: true,
                disability: {
                  type: "IN",
                  field: "cancelled",
                  values: [true],
                },
              },
            ],
          },
        ],
      },
      {
        name: "section2",
        type: "formSection",
        alignment: "column",
        props: {
          width: "25vw",
          spacing: "0.75vw",
          justify: "start",
        },
        childFields: [
          {
            name: "fileInfos",
            label: "File Infos",
            type: "attachment",
            allowUpdate: true,
            allowNull: true,
            props: {
              width: "25vw",
              spacing: "0.75vw",
              justify: "start",
            },
          },
        ],
      },
    ],
  },
];

export const cbSheetColumns = [
  {
    name: "id",
    label: "CBS No",
    type: "text",
  },
  {
    name: "docDate",
    label: "CBS Date",
    type: "date",
    allowNull: true,
    format: (value) => formatDate(value),
  },
  {
    name: "docLocatorCode",
    label: "Locator",
    type: "text",
  },
  {
    name: "docLocatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "docStateName",
    label: "State",
    type: "text",
  },
  {
    name: "projectType",
    label: "Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "projectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "workId",
    label: "CWO/Job Id",
    type: "text",
  },
  {
    name: "workType",
    label: "Work Type",
    type: "text",
    format: (value) => translatePicklistValue(value, [...jobTypeValues, ...cwoTypeValues]),
  },
  {
    name: "totalValue",
    label: "Total Value",
    type: "number",
  },
  {
    name: "cancelled",
    label: "Cancelled",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "submit",
    label: "Submit",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "closed",
    label: "Closed",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

export const cbSheetExcelColumns = [
  {
    name: "id",
    label: "CBS No",
    type: "text",
  },
  {
    name: "docDate",
    label: "CBS Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "docLocatorCode",
    label: "Locator",
    type: "text",
  },
  {
    name: "docLocatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "docStationName",
    label: "Station Name",
    type: "text",
  },
  {
    name: "docStateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "invoiceMBNo",
    label: "Invoice/MB No",
    type: "text",
  },
  {
    name: "invoiceMBDate",
    label: "Invoice/MB Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "materialType",
    label: "Mat Type",
    allowNull: true,
    type: "text",
    format: (value) => translatePicklistValue(value, materialTypeValues),
  },
  {
    name: "projectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "virtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "cwoId",
    label: "CWO No",
    type: "text",
  },
  {
    name: "cwoType",
    label: "CWO Type",
    type: "text",
    format: (value) => translatePicklistValue(value, cwoTypeValues),
  },
  {
    name: "jobId",
    label: "Job No",
    type: "text",
  },
  {
    name: "jobType",
    label: "Job Type",
    type: "text",
    format: (value) => translatePicklistValue(value, jobTypeValues),
  },
  {
    name: "totalValue",
    label: "Total Value",
    type: "number",
  },
  {
    name: "remarks",
    label: "Remarks",
    type: "text",
  },
  {
    name: "cancelled",
    label: "Cancelled",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "submit",
    label: "Submit",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "closed",
    label: "Closed",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

export const cbSheetSearchColumns = [
  {
    name: "id",
    label: "CBS No",
    type: "text",
    defaultValue: null,
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "docDate",
    label: "CBS Date",
    type: "date",
    allowNull: true,
    format: (value) => formatDate(value),
  },
  {
    name: "docLocatorCode",
    label: "Locator",
    type: "text",
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "docStateName",
    label: "State",
    type: "text",
  },
  {
    name: "projectType",
    label: "Type",
    defaultValue: "",
    type: "picklist",
    values: projectTypeValues,
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "projectCode",
    label: "Project",
    type: "text",
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "workId",
    label: "CWO/Job Id",
    type: "text",
    props: {
      widthFactor: 0.8,
    },
  },
  // {
  //   name: "workType",
  //   label: "Work Type",
  //   type: "picklist",
  //   defaultValue: "",
  //   values: workTypeValues,
  // },
  {
    name: "cancelled",
    label: "Cancelled",
    type: "picklist",
    defaultValue: null,
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.6,
    },
  },
  {
    name: "submit",
    label: "Submit",
    type: "picklist",
    defaultValue: null,
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.6,
    },
  },
  {
    name: "closed",
    label: "Closed",
    type: "picklist",
    defaultValue: null,
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.6,
    },
  },
];

export const cbSheetDetailExcelColumns = [
  {
    name: "cbsId",
    label: "CBS No",
    type: "text",
  },
  {
    name: "docDate",
    label: "CBS Date",
    type: "date",
    allowNull: true,
    format: (value) => formatDate(value),
  },
  {
    name: "docLocatorCode",
    label: "Locator",
    type: "text",
  },
  {
    name: "docLocatorName",
    label: "Locator Name",
    type: "text",
  },
  {
    name: "docStationName",
    label: "Station Name",
    type: "text",
  },
  {
    name: "docStateName",
    label: "State Name",
    type: "text",
  },
  {
    name: "invoiceMBNo",
    label: "Invoice/MB No",
    type: "text",
  },
  {
    name: "invoiceMBDate",
    label: "Invoice/MB Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "materialType",
    label: "Mat Type",
    allowNull: true,
    type: "text",
    format: (value) => translatePicklistValue(value, materialTypeValues),
  },
  {
    name: "projectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "virtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "cwoId",
    label: "CWO No",
    type: "text",
  },
  {
    name: "cwoType",
    label: "CWO Type",
    type: "text",
    format: (value) => translatePicklistValue(value, cwoTypeValues),
  },
  {
    name: "jobId",
    label: "Job No",
    type: "text",
  },
  {
    name: "jobType",
    label: "Job Type",
    type: "text",
    format: (value) => translatePicklistValue(value, jobTypeValues),
  },
  {
    name: "totalValue",
    label: "Total Value",
    type: "number",
  },

  {
    name: "itemName",
    label: "Item Name",
    type: "text",
  },
  {
    name: "itemId",
    label: "Item Id",
    type: "text",
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "uom",
    label: "Unit",
    type: "text",
  },
  {
    name: "totalQuantity",
    label: "Used Quantity",
    type: "number",
  },
  {
    name: "rate",
    label: "Rate",
    type: "number",
  },
  {
    name: "totalAmount",
    label: "Total Amount",
    type: "number",
  },
  {
    name: "id",
    label: "Row Id",
    type: "text",
  },
  {
    name: "cancelled",
    label: "Cancelled",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "submit",
    label: "Submit",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "closed",
    label: "Closed",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];
