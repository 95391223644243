import { formatDate } from "../services/dateUtils";
import { Field } from "../types/field";
import { projectTypeValues } from "./project";
import { translatePicklistValue } from "../services/fieldsUtils";
import { locatorTypeValues } from "../standardEntities/locator";

export const materialTypeValues = [
  { value: "F", label: "Fresh" },
  // { value: "R", label: "Repaired" },
  { value: "D", label: "Defective" },
];

export const inTransactionTypeValues = [
  // { value: "PU", label: "Purchase" },
  { value: "OT", label: "Owner Loc Tfr" },
  // { value: "ET", label: "External Site Tfr" },
  { value: "PS", label: "Partner Store Tfr", visibility: { type: "IN", field: "transactionType", values: ["PS"] } },
  { value: "CS", label: "Contractor Store Tfr", visibility: { type: "IN", field: "transactionType", values: ["CS"] } },
  { value: "OS", label: "Opening Stock", visibility: { type: "IN", field: "withinState", values: [true] } },
  { value: "MG", label: "Misc. Gain", visibility: { type: "IN", field: "withinState", values: [true] } },
  { value: "IS", label: "Internal Shift", visibility: { type: "IN", field: "transactionType", values: ["IS"] } },
];

const childWorkOrderRequiredFilter = {
  type: "AND",
  filters: [
    { type: "IN", field: "docLocatorType", values: ["CS"] },
    { type: "IN", field: "projectType", values: ["FTTH"] },
  ],
};

const notChildWorkOrderRequiredFilter = {
  type: "NOT",
  filters: [childWorkOrderRequiredFilter],
};

const childWorkOrderAllowFilter = {
  type: "AND",
  filters: [
    { type: "IN", field: "docLocatorType", values: ["CS", "PS"] },
    { type: "IN", field: "projectType", values: ["FTTH"] },
  ],
};

export const receiverDocTypeValues = [
  { value: "PO", label: "Purchase Order", visibility: notChildWorkOrderRequiredFilter },
  { value: "LO", label: "Lease Order", visibility: notChildWorkOrderRequiredFilter },
  { value: "MR", label: "Material Request", visibility: notChildWorkOrderRequiredFilter },
  { value: "CO", label: "Child Work Order", visibility: childWorkOrderAllowFilter },
  { value: "NA", label: "N/A", visibility: notChildWorkOrderRequiredFilter },
];

export const referenceDocTypeValues = [
  { value: "GP", label: "Gate Pass" },
  { value: "NA", label: "N/A" },
];

export const senderDocTypeValues = [
  { value: "SI", label: "Sales Invoice", visibility: { type: "NIN", field: "transactionType", values: ["PS", "CS"] } },
  { value: "DC", label: "Delivery Challan", visibility: { type: "NIN", field: "transactionType", values: ["PS", "CS"] } },
  { value: "GP", label: "Gate Pass", visibility: { type: "IN", field: "transactionType", values: ["PS", "CS"] } },
  { value: "NA", label: "N/A" },
];

export const transportModeValues = [
  { value: "TP", label: "Transporter" },
  { value: "CR", label: "Courier" },
  { value: "SF", label: "Self" },
];

export const materialInwardDetailFields: Field[] = [
  {
    name: "sno",
    label: "SNo",
    type: "text",
    autoFilled: true,
    allowNull: true,
    props: {
      widthFactor: 0.5,
    },
  },
  {
    name: "itemName",
    label: "Item Name",
    type: "lookup",
    lookupType: "item",
    lookupFieldName: "itemName",
    props: {
      widthFactor: 3,
    },
    lookupFilter: {
      type: "AND",
      filters: [
        {
          type: "EQUALS",
          field: "itemCode",
          resolveValue: true,
          valueFieldName: "itemCode",
        },
      ],
    },
    dependentFields: [
      {
        lookupFieldName: "id",
        fieldName: "itemCustCode",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "itemCode",
        fieldName: "itemCode",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "id",
        fieldName: "itemId",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "uom",
        fieldName: "uom",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "serialAllow",
        fieldName: "serialAllow",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "serialMin",
        fieldName: "serialMin",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "serialMax",
        fieldName: "serialMax",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "decimalFlg",
        fieldName: "decimalFlg",
      },
    ],
    linkedFields: [
      {
        name: "totalQuantity",
        value: "",
      },
      {
        name: "basicAmount",
        value: "",
      },
    ],
  },
  // {
  //   name: "itemCode",
  //   label: "Item Code",
  //   type: "text",
  //   autoFilled: true,
  //   props: {
  //     widthFactor: 1.4,
  //   },
  // },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
    allowNull: true,
    autoFilled: true,
    props: {
      widthFactor: 1.6,
    },
  },
  {
    name: "uom",
    label: "Unit",
    type: "text",
    autoFilled: true,
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "totalQuantity",
    label: "Inward Quantity",
    type: "number",
    defaultValue: 0,
    allowUpdate: true,
    decimalFlg: true,
  },
  {
    name: "basicAmount",
    label: "Basic Amount",
    type: "number",
    decimal: true,
    allowUpdate: true,
  },
  {
    name: "rate",
    label: "Rate",
    type: "number",
    autoFilled: true,
    decimal: true,
    valueScript: {
      operation: "divide",
      valueFields: ["totalAmount", "totalQuantity"],
    },
  },
  {
    name: "totalAmount",
    label: "Total Amount",
    type: "number",
    autoFilled: true,
    decimal: true,
    valueScript: {
      operation: "multi",
      valueFields: ["basicAmount"],
      globalFields: ["invByBasic"],
    },
  },
  {
    name: "id",
    label: "Row Id",
    type: "text",
    allowNull: true,
    autoFilled: true,
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "itemId",
    label: "Item Id",
    type: "text",
    autoFilled: true,
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "availableQuantity",
    label: "Available Quantity",
    type: "text",
    allowNull: true,
    autoFilled: true,
  },
];

export const materialInwardFields: Field[] = [
  {
    name: "sectionA",
    type: "formSection",
    alignment: "row",
    props: {
      width: "25vw",
      spacing: "2vw",
    },
    childFields: [
      {
        name: "section1",
        type: "formSection",
        alignment: "column",
        props: {
          width: "25vw",
          spacing: "0.75vw",
        },
        childFields: [
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "docLocatorName",
                label: "Inward Locator Name",
                props: {
                  width: "17vw",
                },
                type: "lookup",
                lookupType: "locator",
                lookupFieldName: "locatorCode",
                lookupCategory: "docLocator",
                lookupParams: [
                  {
                    key: "projectCode",
                    resolveValue: true,
                    valueFieldName: "projectCode",
                  },
                  {
                    key: "docCompanyCode",
                    resolveValue: true,
                    valueFieldName: "docCompanyCode",
                  },
                ],
                lookupFilter: {
                  type: "AND",
                  filters: [
                    {
                      type: "IN",
                      field: "locatorType",
                      values: ["PS", "CS"],
                    },
                    {
                      type: "EQUALS",
                      field: "locatorCode",
                      resolveValue: true,
                      valueFieldName: "docLocatorCode",
                    },
                  ],
                },
                dependentFields: [
                  {
                    lookupFieldName: "id",
                    fieldName: "docLocatorCode",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "locatorType",
                    fieldName: "docLocatorType",
                  },
                  {
                    additionalFieldName: "company",
                    lookupFieldName: "companyCode",
                    fieldName: "docCompanyCode",
                  },
                  {
                    additionalFieldName: "company",
                    lookupFieldName: "companyName",
                    fieldName: "docCompanyName",
                  },
                  {
                    lookupFieldName: "description",
                    fieldName: "docStationCode",
                  },
                  {
                    additionalFieldName: "station",
                    lookupFieldName: "stationName",
                    fieldName: "docStationName",
                  },
                  {
                    additionalFieldName: "station",
                    lookupFieldName: "stateName",
                    fieldName: "docStateName",
                  },
                  {
                    additionalFieldName: "station",
                    lookupFieldName: "stateCode",
                    fieldName: "docStateCode",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "address1",
                    fieldName: "docAddress1",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "address2",
                    fieldName: "docAddress2",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "gstNo",
                    fieldName: "docGSTNo",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "todayDate",
                    fieldName: "docDate",
                  },
                ],
                linkedFields: [
                  {
                    name: "projectCode",
                    value: "",
                  },
                  {
                    name: "projectName",
                    value: "",
                  },
                  {
                    name: "projectType",
                    value: "FF",
                  },
                  {
                    name: "materialType",
                    value: "F",
                  },
                  {
                    name: "virtualLocator",
                    value: "",
                  },
                  {
                    name: "transactionType",
                    value: "OT",
                  },
                  {
                    name: "senderProjectCode",
                    value: "",
                  },
                  {
                    name: "senderProjectName",
                    value: "",
                  },
                  {
                    name: "senderProjectType",
                    value: "FF",
                  },
                  {
                    name: "senderMaterialType",
                    value: "F",
                  },
                  {
                    name: "senderLocatorName",
                    value: "",
                  },
                  {
                    name: "senderLocatorCode",
                    value: "",
                  },
                  {
                    name: "senderCompanyName",
                    value: "",
                  },
                  {
                    name: "senderCompanyCode",
                    value: "",
                  },
                  {
                    name: "receiverDocNo",
                    value: null,
                  },
                  {
                    name: "receiverDocDate",
                    value: null,
                  },
                ],
                disability: {
                  type: "EXISTS",
                  field: "details",
                },
              },
              {
                name: "docLocatorCode",
                label: "Locator Code",
                type: "text",
                props: {
                  width: "7vw",
                },
                autoFilled: true,
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "docCompanyCode",
                label: "Company Code",
                type: "text",
                autoFilled: true,
                defaultValue: null,
                props: {
                  width: "7vw",
                },
              },
              {
                name: "docCompanyName",
                label: "Inward Company Name",
                type: "text",
                autoFilled: true,
                displayOnly: true,
                props: {
                  width: "17vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "docStationName",
                label: "Inward Station",
                type: "text",
                displayOnly: true,
                props: {
                  width: "12vw",
                },
              },
              {
                name: "docStateName",
                label: "Inward State",
                type: "text",
                displayOnly: true,
                props: {
                  width: "12vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "docLocatorType",
                label: "Locator Type",
                type: "picklist",
                props: {
                  width: "12vw",
                },
                values: locatorTypeValues,
                autoFilled: true,
                allowNull: true,
                defaultValue: null,
              },
              {
                name: "materialType",
                label: "Material Type",
                type: "picklist",
                allowUpdate: true,
                defaultValue: "F",
                values: materialTypeValues,
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "EXISTS",
                      field: "details",
                    },
                    {
                      type: "MISSING",
                      field: "docLocatorCode",
                    },
                    {
                      type: "NIN",
                      field: "transactionType",
                      values: ["OS", "MG"],
                    },
                  ],
                },
                props: {
                  width: "12vw",
                },
                linkedFields: [
                  {
                    name: "senderMaterialType",
                    resolveValue: true,
                    valueFieldName: "materialType",
                  },
                ],
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "projectType",
                label: "Project Type",
                type: "picklist",
                allowUpdate: true,
                defaultValue: "FF",
                values: projectTypeValues,
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "EXISTS",
                      field: "details",
                    },
                    {
                      type: "MISSING",
                      field: "docLocatorCode",
                    },
                  ],
                },
                props: {
                  width: "9vw",
                },
                linkedFields: [
                  {
                    name: "projectName",
                    value: "",
                  },
                  {
                    name: "projectCode",
                    value: "",
                  },
                  {
                    name: "senderProjectName",
                    value: "",
                  },
                  {
                    name: "senderProjectCode",
                    value: "",
                  },
                ],
              },
              {
                name: "docOwnerCode",
                label: "Project Owner",
                type: "text",
                autoFilled: true,
                allowNull: true,
                defaultValue: null,
                props: {
                  width: "7vw",
                },
              },
              {
                name: "projectCode",
                label: "Project Code",
                type: "text",
                autoFilled: true,
                defaultValue: null,
                props: {
                  width: "7vw",
                },
                linkedFields: [
                  {
                    name: "senderProjectCode",
                    resolveValue: true,
                    valueFieldName: "projectCode",
                  },
                ],
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "projectName",
                label: "Project Name",
                type: "lookup",
                allowUpdate: true,
                lookupType: "project",
                lookupCategory: "inwardProject",
                props: {
                  width: "25vw",
                },
                lookupFilter: {
                  type: "AND",
                  filters: [
                    {
                      type: "EQUALS",
                      field: "projectCode",
                      resolveValue: true,
                      valueFieldName: "projectCode",
                    },
                    {
                      type: "EQUALS",
                      field: "projectType",
                      resolveValue: true,
                      valueFieldName: "projectType",
                    },
                  ],
                },
                lookupParams: [
                  {
                    key: "docStateCode",
                    resolveValue: true,
                    valueFieldName: "docStateCode",
                  },
                  {
                    key: "locatorType",
                    resolveValue: true,
                    valueFieldName: "docLocatorType",
                  },
                  {
                    key: "locatorCode",
                    resolveValue: true,
                    valueFieldName: "docLocatorCode",
                  },
                  {
                    key: "projectType",
                    resolveValue: true,
                    valueFieldName: "projectType",
                  },
                ],
                dependentFields: [
                  {
                    lookupFieldName: "id",
                    fieldName: "projectCode",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "virtualLocator",
                    fieldName: "virtualLocator",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "ownerCode",
                    fieldName: "docOwnerCode",
                  },
                ],
                linkedFields: [
                  {
                    name: "senderProjectType",
                    resolveValue: true,
                    valueFieldName: "projectType",
                  },
                  {
                    name: "senderProjectName",
                    resolveValue: true,
                    valueFieldName: "projectName",
                  },
                ],
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "EXISTS",
                      field: "details",
                    },
                    {
                      type: "MISSING",
                      field: "docLocatorCode",
                    },
                  ],
                },
              },
            ],
          },

          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "receiverDocType",
                label: "Receiver Doc Type",
                type: "picklist",
                defaultValue: "NA",
                allowUpdate: true,
                props: {
                  width: "12vw",
                },
                linkedFields: [
                  { name: "receiverDocNo", value: null },
                  { name: "receiverDocDate", value: null },
                ],
                values: receiverDocTypeValues,
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "MISSING",
                      field: "projectCode",
                    },
                    {
                      type: "MISSING",
                      field: "docLocatorCode",
                    },
                  ],
                },
              },
              {
                name: "docGSTNo",
                label: "Receiver GST No",
                type: "text",
                autoFilled: true,
                displayOnly: true,
                allowNull: true,
                props: {
                  width: "12vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "receiverDocNo",
                label: "Receiver Doc No",
                type: "text",
                allowUpdate: true,
                defaultValue: "",
                props: {
                  width: "12vw",
                },
                disability: {
                  type: "IN",
                  field: "receiverDocType",
                  values: ["NA", "CO"],
                },
                visibility: {
                  type: "NIN",
                  field: "receiverDocType",
                  values: ["CO"],
                },
              },
              {
                name: "receiverDocNo",
                label: "Receiver Doc No",
                type: "lookup",
                lookupType: "child-work-order",
                lookupCategory: "inwardChildWorkOrder",
                lookupFilter: {
                  type: "AND",
                  filters: [
                    {
                      type: "EQUALS",
                      field: "companyCode",
                      resolveValue: true,
                      valueFieldName: "docCompanyCode",
                    },
                    {
                      type: "EQUALS",
                      field: "projectCode",
                      resolveValue: true,
                      valueFieldName: "projectCode",
                    },
                  ],
                },
                allowUpdate: true,
                defaultValue: "",
                dependentFields: [
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "cwoDate",
                    fieldName: "receiverDocDate",
                  },
                ],
                props: {
                  width: "12vw",
                },
                visibility: {
                  type: "IN",
                  field: "receiverDocType",
                  values: ["CO"],
                },
                disability: {
                  type: "NIN",
                  field: "receiverDocType",
                  values: ["CO"],
                },
              },
              {
                name: "receiverDocDate",
                label: "Receiver Doc Date",
                type: "date",
                constraints: [{ type: "DATE_RANGE", beforeNow: true }],
                allowUpdate: true,
                defaultValue: null,
                props: {
                  width: "12vw",
                },
                disability: {
                  type: "IN",
                  field: "receiverDocType",
                  values: ["NA", "CO"],
                },
              },
            ],
          },
        ],
      },
      {
        name: "section2",
        type: "formSection",
        alignment: "column",
        props: {
          width: "17vw",
          spacing: "0.75vw",
          justify: "top",
        },
        childFields: [
          {
            name: "sections",
            type: "formSection",
            props: {
              justify: "right",
            },
            childFields: [
              {
                name: "sections",
                type: "formSection",
                childFields: [
                  {
                    name: "withinState",
                    label: "Within State",
                    type: "checkbox",
                    defaultValue: true,
                    allowUpdate: true,
                    linkedFields: [
                      {
                        name: "transactionType",
                        value: "OT",
                      },
                      {
                        name: "materialType",
                        value: "F",
                      },
                      {
                        name: "senderMaterialType",
                        value: "F",
                      },
                      {
                        name: "senderLocatorName",
                        value: "",
                      },
                      {
                        name: "senderLocatorCode",
                        value: "",
                      },
                      {
                        name: "senderCompanyName",
                        value: "",
                      },
                      {
                        name: "senderCompanyCode",
                        value: "",
                      },
                    ],
                    disability: {
                      type: "EXISTS",
                      field: "details",
                    },
                  },
                  {
                    name: "transactionType",
                    label: "Transaction Type",
                    type: "picklist",
                    values: inTransactionTypeValues,
                    allowUpdate: true,
                    defaultValue: "OT",
                    disability: {
                      type: "OR",
                      filters: [
                        {
                          type: "EXISTS",
                          field: "details",
                        },
                        {
                          type: "MISSING",
                          field: "docLocatorCode",
                        },
                      ],
                    },
                    linkedFields: [
                      {
                        name: "materialType",
                        filteredValues: [
                          {
                            filter: { type: "NIN", field: "transactionType", values: ["OS", "MG"] },
                            value: "F",
                          },
                        ],
                        resolveValue: true,
                        valueFieldName: "materialType",
                      },
                      {
                        name: "senderStateCode",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "withinState", values: [true] },
                            resolveValue: true,
                            valueFieldName: "docStateCode",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "senderCompanyName",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "transactionType", values: ["OS", "MG"] },
                            resolveValue: true,
                            valueFieldName: "docCompanyName",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "senderCompanyCode",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "transactionType", values: ["OS", "MG"] },
                            resolveValue: true,
                            valueFieldName: "docCompanyCode",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "senderLocatorName",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "transactionType", values: ["OS", "MG"] },
                            resolveValue: true,
                            valueFieldName: "docLocatorName",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "senderLocatorCode",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "transactionType", values: ["OS", "MG"] },
                            resolveValue: true,
                            valueFieldName: "docLocatorCode",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "senderLocatorType",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "transactionType", values: ["OS", "MG"] },
                            resolveValue: true,
                            valueFieldName: "docLocatorType",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "senderAddress1",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "transactionType", values: ["OS", "MG"] },
                            resolveValue: true,
                            valueFieldName: "docAddress1",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "senderAddress2",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "transactionType", values: ["OS", "MG"] },
                            resolveValue: true,
                            valueFieldName: "docAddress2",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "senderStationCode",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "transactionType", values: ["OS", "MG"] },
                            resolveValue: true,
                            valueFieldName: "docStationCode",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "senderStationName",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "transactionType", values: ["OS", "MG"] },
                            resolveValue: true,
                            valueFieldName: "docStationName",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "senderStateName",
                        filteredValues: [
                          {
                            filter: { type: "IN", field: "transactionType", values: ["OS", "MG"] },
                            resolveValue: true,
                            valueFieldName: "docStateName",
                          },
                        ],
                        value: "",
                      },
                      {
                        name: "senderProjectType",
                        resolveValue: true,
                        valueFieldName: "projectType",
                      },
                      {
                        name: "senderMaterialType",
                        resolveValue: true,
                        valueFieldName: "materialType",
                      },
                      {
                        name: "senderProjectName",
                        resolveValue: true,
                        valueFieldName: "projectName",
                      },
                      {
                        name: "senderProjectCode",
                        resolveValue: true,
                        valueFieldName: "projectCode",
                      },
                      {
                        name: "senderOwnerCode",
                        resolveValue: true,
                        valueFieldName: "docOwnerCode",
                      },
                      {
                        name: "senderDocNo",
                        value: "",
                      },
                      {
                        name: "senderDocDate",
                        value: "",
                      },
                    ],
                    props: {
                      width: "10vw",
                    },
                  },
                ],
              },
            ],
          },
          {
            name: "virtualLocator",
            label: "Receiver Virtual Locator",
            type: "text",
            autoFilled: true,
            displayOnly: true,
            allowNull: true,
            props: {
              width: "21vw",
            },
          },
          {
            name: "sections",
            type: "formSection",
            visibility: {
              type: "IN",
              field: "transactionType",
              values: ["IS", "PS", "CS", "OS", "MG"],
            },
            childFields: [
              {
                name: "senderVirtualLocator",
                label: "Sender Virtual Locator",
                type: "text",
                allowNull: true,
                autoFilled: true,
                displayOnly: true,
                props: {
                  width: "21vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "basicValue",
                label: "Basic Value",
                type: "number",
                defaultValue: "0",
                setDefaultOnDisable: true,
                decimal: true,
                allowUpdate: true,
                props: {
                  width: "10vw",
                },
              },
              {
                name: "freightValue",
                label: "Freight Value",
                type: "number",
                defaultValue: "0",
                setDefaultOnDisable: true,
                allowUpdate: true,
                decimal: true,
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "IN",
                      field: "basicValue",
                      values: ["0", ""],
                    },
                    {
                      type: "NIN",
                      field: "transactionType",
                      values: ["PU", "OT"],
                    },
                  ],
                },
                props: {
                  width: "10vw",
                },
                visibility: {
                  type: "NIN",
                  field: "transactionType",
                  values: ["IS"],
                },
              },
              {
                name: "totalValue",
                label: "Total Invoice Value",
                type: "number",
                autoFilled: true,
                defaultValue: "0",
                setDefaultOnDisable: true,
                decimal: true,
                valueScript: {
                  operation: "add",
                  valueFields: ["basicValue", "iGSTValue", "sGSTValue", "sGSTValue", "freightValue"],
                },
                props: {
                  width: "10vw",
                },
                visibility: {
                  type: "IN",
                  field: "transactionType",
                  values: ["IS"],
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            visibility: {
              type: "NIN",
              field: "transactionType",
              values: ["IS"],
            },
            childFields: [
              {
                name: "sGSTValue",
                label: "SGST Value",
                type: "number",
                defaultValue: "0",
                setDefaultOnDisable: true,
                decimal: true,
                allowUpdate: true,
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "IN",
                      field: "withinState",
                      values: [false],
                    },
                    {
                      type: "NIN",
                      field: "iGSTValue",
                      values: ["0", ""],
                    },
                    {
                      type: "IN",
                      field: "basicValue",
                      values: ["0", ""],
                    },
                    {
                      type: "NIN",
                      field: "transactionType",
                      values: ["PU", "OT"],
                    },
                  ],
                },
                props: {
                  width: "10vw",
                },
              },
              {
                name: "cGSTValue",
                label: "CGST Value",
                type: "number",
                defaultValue: "0",
                setDefaultOnDisable: true,
                decimal: true,
                allowUpdate: true,
                autoFilled: true,
                valueScript: { operation: "add", valueFields: ["sGSTValue"] },
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "NIN",
                      field: "iGSTValue",
                      values: ["0", ""],
                    },
                    {
                      type: "IN",
                      field: "withinState",
                      values: [false],
                    },
                  ],
                },
                props: {
                  width: "10vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            visibility: {
              type: "NIN",
              field: "transactionType",
              values: ["IS"],
            },
            childFields: [
              {
                name: "iGSTValue",
                label: "IGST Value",
                type: "number",
                defaultValue: "0",
                setDefaultOnDisable: true,
                decimal: true,
                allowUpdate: true,
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "NIN",
                      field: "sGSTValue",
                      values: ["0", ""],
                    },
                    {
                      type: "IN",
                      field: "basicValue",
                      values: ["0", ""],
                    },
                    {
                      type: "NIN",
                      field: "transactionType",
                      values: ["PU", "OT"],
                    },
                    {
                      type: "IN",
                      field: "withinState",
                      values: [true],
                    },
                  ],
                },
                props: {
                  width: "10vw",
                },
              },
              {
                name: "totalValue",
                label: "Total Invoice Value",
                type: "number",
                autoFilled: true,
                defaultValue: "0",
                setDefaultOnDisable: true,
                decimal: true,
                valueScript: {
                  operation: "add",
                  valueFields: ["basicValue", "iGSTValue", "sGSTValue", "sGSTValue", "freightValue"],
                },
                props: {
                  width: "10vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "id",
                label: "MIR No",
                type: "text",
                autoFilled: true,
                allowNull: true,
                props: {
                  width: "10vw",
                },
              },
              {
                name: "docDate",
                label: "MIR Date",
                type: "date",
                displayOnly: true,
                props: {
                  width: "10vw",
                },
              },
            ],
          },
        ],
      },
      {
        name: "section3",
        type: "formSection",
        alignment: "column",
        props: {
          width: "25vw",
          spacing: "0.75vw",
        },
        childFields: [
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "senderCompanyName",
                label: "Sender Company Name",
                type: "lookup",
                lookupType: "company",
                // lookupCategory: "senderCompany",
                allowUpdate: true,
                props: {
                  width: "17vw",
                },
                dependentFields: [
                  {
                    lookupFieldName: "id",
                    fieldName: "senderCompanyCode",
                  },
                ],
                lookupParams: [
                  {
                    key: "docCompanyCode",
                    resolveValue: true,
                    valueFieldName: "docCompanyCode",
                  },
                  {
                    key: "transactionType",
                    resolveValue: true,
                    valueFieldName: "transactionType",
                  },
                  {
                    key: "senderLocatorCode",
                    resolveValue: true,
                    valueFieldName: "senderLocatorCode",
                  },
                  {
                    key: "withinState",
                    resolveValue: true,
                    valueFieldName: "withinState",
                  },
                ],
                // linkedFields: [
                //   {
                //     name: "senderLocatorName",
                //     filteredValues: [
                //       {
                //         filter: { type: "IN", field: "transactionType", values: ["OS", "MG"] },
                //         resolveValue: true,
                //         valueFieldName: "docLocatorName",
                //       },
                //     ],
                //     value: "",
                //   },
                //   {
                //     name: "senderLocatorCode",
                //     filteredValues: [
                //       {
                //         filter: { type: "IN", field: "transactionType", values: ["OS", "MG"] },
                //         resolveValue: true,
                //         valueFieldName: "docLocatorCode",
                //       },
                //     ],
                //     value: "",
                //   },
                lookupFilter: {
                  type: "AND",
                  filters: [
                    {
                      type: "IN",
                      field: "ownerFlg",
                      values: [true],
                    },
                    {
                      type: "EQUALS",
                      field: "companyCode",
                      resolveValue: true,
                      valueFieldName: "senderCompanyCode",
                    },
                  ],
                },
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "EXISTS",
                      field: "details",
                    },
                    {
                      type: "MISSING",
                      field: "docCompanyCode",
                    },
                    {
                      type: "MISSING",
                      field: "projectCode",
                    },
                    {
                      type: "IN",
                      field: "transactionType",
                      values: ["OS", "MG"],
                    },
                  ],
                },
              },
              {
                name: "senderCompanyCode",
                label: "Company Code",
                type: "text",
                allowNull: true,
                autoFilled: true,
                props: {
                  width: "7vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "senderLocatorCode",
                label: "Locator Code",
                type: "text",
                allowNull: true,
                autoFilled: true,
                props: {
                  width: "7vw",
                },
              },
              {
                name: "senderLocatorName",
                label: "Sender Locator Name",
                type: "lookup",
                lookupType: "locator",
                lookupCategory: "senderLocator",
                props: {
                  width: "17vw",
                },
                allowUpdate: true,
                lookupFilter: {
                  type: "AND",
                  filters: [
                    {
                      type: "EQUALS",
                      field: "locatorCode",
                      resolveValue: true,
                      valueFieldName: "senderLocatorCode",
                    },
                    {
                      type: "EQUALS",
                      field: "companyCode",
                      resolveValue: true,
                      valueFieldName: "senderCompanyCode",
                    },
                  ],
                },
                lookupParams: [
                  {
                    key: "docStateCode",
                    resolveValue: true,
                    valueFieldName: "docStateCode",
                  },
                  {
                    key: "withinState",
                    resolveValue: true,
                    valueFieldName: "withinState",
                  },
                  {
                    key: "docLocatorCode",
                    resolveValue: true,
                    valueFieldName: "docLocatorCode",
                  },
                  {
                    key: "transactionType",
                    resolveValue: true,
                    valueFieldName: "transactionType",
                  },
                  {
                    key: "locatorCompany",
                    resolveValue: true,
                    valueFieldName: "senderCompanyCode",
                  },
                  {
                    key: "materialType",
                    resolveValue: true,
                    valueFieldName: "materialType",
                  },
                ],
                dependentFields: [
                  {
                    lookupFieldName: "id",
                    fieldName: "senderLocatorCode",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "locatorType",
                    fieldName: "senderLocatorType",
                  },
                  {
                    lookupFieldName: "description",
                    fieldName: "senderStationCode",
                  },
                  {
                    additionalFieldName: "station",
                    lookupFieldName: "stationName",
                    fieldName: "senderStationName",
                  },
                  {
                    additionalFieldName: "station",
                    lookupFieldName: "stateName",
                    fieldName: "senderStateName",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "gstNo",
                    fieldName: "senderGSTNo",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "address1",
                    fieldName: "senderAddress1",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "address2",
                    fieldName: "senderAddress2",
                  },
                ],
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "EXISTS",
                      field: "details",
                    },
                    {
                      type: "MISSING",
                      field: "senderCompanyCode",
                    },
                    {
                      type: "IN",
                      field: "transactionType",
                      values: ["OS", "MG"],
                    },
                  ],
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            visibility: {
              type: "IN",
              field: "transactionType",
              values: ["IS", "PS", "CS", "OS", "MG"],
            },
            childFields: [
              {
                name: "senderStationName",
                label: "Sender Station",
                type: "text",
                autoFilled: true,
                props: {
                  width: "12vw",
                },
              },
              {
                name: "senderStateName",
                label: "Sender State/Country",
                type: "text",
                autoFilled: true,
                props: {
                  width: "12vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            visibility: {
              type: "IN",
              field: "transactionType",
              values: ["IS", "PS", "CS", "OS", "MG"],
            },
            childFields: [
              {
                name: "senderLocatorType",
                label: "Locator Type",
                type: "picklist",
                props: {
                  width: "12vw",
                },
                values: locatorTypeValues,
                autoFilled: true,
                allowNull: true,
                defaultValue: null,
              },
              {
                name: "senderMaterialType",
                label: "Sender Material Type",
                type: "picklist",
                values: materialTypeValues,
                autoFilled: true,
                props: {
                  width: "12vw",
                },
                displayOnly: true,
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            visibility: {
              type: "IN",
              field: "transactionType",
              values: ["IS", "PS", "CS", "OS", "MG"],
            },
            childFields: [
              {
                name: "senderProjectType",
                label: "Sender Project Type",
                type: "picklist",
                values: projectTypeValues,
                autoFilled: true,
                props: {
                  width: "9vw",
                },
                displayOnly: true,
              },
              {
                name: "senderOwnerCode",
                label: "Project Owner",
                type: "text",
                autoFilled: true,
                allowNull: true,
                defaultValue: null,
                props: {
                  width: "7vw",
                },
              },
              {
                name: "senderProjectCode",
                label: "Project Code",
                type: "text",
                autoFilled: true,
                props: {
                  width: "7vw",
                },
                displayOnly: true,
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            visibility: {
              type: "IN",
              field: "transactionType",
              values: ["IS", "PS", "CS", "OS", "MG"],
            },
            childFields: [
              {
                name: "senderProjectName",
                label: "Sender Project Name",
                type: "lookup",
                lookupType: "project",
                lookupCategory: "senderProject",
                lookupFilter: {
                  type: "AND",
                  filters: [
                    {
                      type: "EQUALS",
                      field: "projectCode",
                      resolveValue: true,
                      valueFieldName: "senderProjectCode",
                    },
                  ],
                },
                lookupParams: [
                  {
                    key: "docStateCode",
                    resolveValue: true,
                    valueFieldName: "docStateCode",
                  },
                  {
                    key: "senderCompanyCode",
                    resolveValue: true,
                    valueFieldName: "senderCompanyCode",
                  },
                  {
                    key: "projectType",
                    resolveValue: true,
                    valueFieldName: "projectType",
                  },
                ],
                dependentFields: [
                  {
                    lookupFieldName: "id",
                    fieldName: "senderProjectCode",
                  },
                  {
                    lookupFieldName: "description",
                    fieldName: "senderProjectType",
                  },
                  {
                    additionalFieldName: "details",
                    lookupFieldName: "virtualLocator",
                    fieldName: "senderVirtualLocator",
                  },
                ],
                props: {
                  width: "25vw",
                },
                disability: {
                  type: "IN",
                  field: "transactionType",
                  values: ["IS", "PS", "CS", "OS", "MG"],
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            visibility: {
              type: "NIN",
              field: "transactionType",
              values: ["IS", "PS", "CS", "OS", "MG"],
            },
            childFields: [
              {
                name: "senderAddress1",
                label: "Address1 ",
                type: "text",
                autoFilled: true,
                props: {
                  width: "25vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            visibility: {
              type: "NIN",
              field: "transactionType",
              values: ["IS", "PS", "CS", "OS", "MG"],
            },
            childFields: [
              {
                name: "senderAddress2",
                label: "Address2 ",
                type: "text",
                allowNull: true,
                autoFilled: true,
                props: {
                  width: "25vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            visibility: {
              type: "NIN",
              field: "transactionType",
              values: ["IS", "PS", "CS", "OS", "MG"],
            },
            childFields: [
              {
                name: "senderStationName",
                label: "Sender Station",
                type: "text",
                autoFilled: true,
                props: {
                  width: "12vw",
                },
              },
              {
                name: "senderStateName",
                label: "Sender State/Country",
                type: "text",
                autoFilled: true,
                props: {
                  width: "12vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            visibility: {
              type: "NIN",
              field: "transactionType",
              values: ["IS", "PS", "CS", "OS", "MG"],
            },
            childFields: [
              {
                name: "senderLocatorType",
                label: "Locator Type",
                type: "picklist",
                props: {
                  width: "12vw",
                },
                values: locatorTypeValues,
                autoFilled: true,
                allowNull: true,
                defaultValue: null,
              },
              {
                name: "senderMaterialType",
                label: "Sender Material Type",
                type: "picklist",
                values: materialTypeValues,
                autoFilled: true,
                props: {
                  width: "12vw",
                },
                displayOnly: true,
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "senderDocType",
                label: "Sender Doc Type",
                type: "picklist",
                allowUpdate: true,
                defaultValue: "NA",
                props: {
                  width: "12vw",
                },
                values: senderDocTypeValues,
                disability: {
                  type: "OR",
                  filters: [
                    {
                      type: "MISSING",
                      field: "senderLocatorCode",
                    },
                  ],
                },
                linkedFields: [
                  { name: "senderDocNo", value: null },
                  { name: "senderDocDate", value: null },
                ],
              },
              {
                name: "senderGSTNo",
                label: "Sender GST No",
                type: "text",
                autoFilled: true,
                allowNull: true,
                displayOnly: true,
                props: {
                  width: "12vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "senderDocNo",
                label: "Sender Doc No",
                type: "text",
                defaultValue: "",
                allowUpdate: true,
                props: {
                  width: "12vw",
                },
                disability: {
                  type: "IN",
                  field: "senderDocType",
                  values: ["NA", "GP"],
                },
                visibility: {
                  type: "NIN",
                  field: "senderDocType",
                  values: ["GP"],
                },
              },
              {
                name: "senderDocNo",
                label: "Sender Doc No",
                type: "lookup",
                lookupType: "gate-pass",
                lookupCategory: "inwardGatePass",
                // lookupFilter: {
                //   type: "IN",
                //   field: "delivered",
                //   values: [false],
                // },
                lookupParams: [
                  {
                    key: "docStateCode",
                    resolveValue: true,
                    valueFieldName: "docStateCode",
                  },
                ],
                allowUpdate: true,
                defaultValue: "",
                props: {
                  width: "12vw",
                },
                visibility: {
                  type: "IN",
                  field: "senderDocType",
                  values: ["GP"],
                },
                disability: {
                  type: "NIN",
                  field: "senderDocType",
                  values: ["GP"],
                },
              },
              {
                name: "senderDocDate",
                label: "Sender Doc Date",
                type: "date",
                allowUpdate: true,
                constraints: [{ type: "DATE_RANGE", beforeNow: true }],
                defaultValue: null,
                props: {
                  width: "12vw",
                },
                disability: {
                  type: "IN",
                  field: "senderDocType",
                  values: ["NA"],
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "details",
    label: "Details",
    allowUpdate: true,
    allowNull: true,
    type: "formTable",
    tableFields: materialInwardDetailFields,
    disability: {
      type: "OR",
      filters: [
        {
          type: "MISSING",
          field: "projectCode",
        },
        {
          type: "MISSING",
          field: "senderLocatorCode",
        },
        {
          type: "MISSING",
          field: "materialType",
        },
        {
          type: "MISSING",
          field: "transactionType",
        },
      ],
    },
    props: {
      width: "82vw",
    },
  },
  {
    name: "sectionB",
    type: "formSection",
    alignment: "row",
    props: {
      width: "25vw",
      spacing: "2vw",
    },
    childFields: [
      {
        name: "section1",
        type: "formSection",
        alignment: "column",
        props: {
          width: "25vw",
          spacing: "0.75vw",
          justify: "top",
        },
        childFields: [
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "transportMode",
                label: "Transport Mode",
                type: "picklist",
                allowUpdate: true,
                defaultValue: "TP",
                props: {
                  width: "12vw",
                },
                values: [
                  { value: "TP", label: "Transporter" },
                  { value: "CR", label: "Courier" },
                  { value: "SF", label: "Self" },
                ],
                linkedFields: [
                  {
                    name: "vendorName",
                    value: "",
                  },
                  {
                    name: "vendorCode",
                    value: "",
                  },
                  {
                    name: "consignmentNote",
                    value: "",
                  },
                ],
              },
              {
                name: "vehicleNo",
                label: "Vehicle Number",
                type: "text",
                allowUpdate: true,
                allowNull: true,
                props: {
                  width: "12vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "vendorName",
                label: "Transporter Name",
                type: "lookup",
                lookupType: "company",
                dependentFields: [
                  {
                    lookupFieldName: "id",
                    fieldName: "vendorCode",
                  },
                ],
                lookupFilter: {
                  type: "AND",
                  filters: [
                    {
                      type: "EQUALS",
                      field: "companyCode",
                      resolveValue: true,
                      valueFieldName: "vendorCode",
                    },
                    {
                      type: "EQUALS",
                      field: "ownerFlg",
                      values: false,
                    },
                    {
                      type: "EQUALS",
                      field: "serviceFlg",
                      values: true,
                    },
                  ],
                },
                defaultValue: null,
                allowUpdate: true,
                props: {
                  width: "17vw",
                },
                disability: {
                  field: "transportMode",
                  type: "IN",
                  values: ["SF"],
                },
              },
              {
                name: "vendorCode",
                label: "Code",
                type: "text",
                defaultValue: null,
                allowNull: true,
                autoFilled: true,
                props: {
                  width: "7vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "consignmentNote",
                label: "Consignment Note",
                type: "text",
                allowNull: true,
                allowUpdate: true,
                defaultValue: null,
                props: {
                  width: "12vw",
                },
                disability: {
                  field: "transportMode",
                  type: "IN",
                  values: ["SF"],
                },
              },
              {
                name: "ewayBillNo",
                label: "Eway Bill No",
                type: "text",
                defaultValue: null,
                allowUpdate: true,
                allowNull: true,
                props: {
                  width: "12vw",
                },
              },
            ],
          },
        ],
      },
      {
        name: "section2",
        type: "formSection",
        alignment: "column",
        props: {
          width: "21vw",
          spacing: "0.75vw",
          justify: "top",
        },
        childFields: [
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "inventoryValue",
                label: "Total Inventory Value",
                type: "number",
                allowNull: true,
                defaultValue: "0",
                decimal: true,
                autoFilled: true,
                props: {
                  width: "10vw",
                },
                valueScript: {
                  operation: "add",
                  valueFields: ["basicValue", "freightValue"],
                },
              },
              {
                name: "totalPkgs",
                label: "Total Packages",
                type: "number",
                allowUpdate: true,
                props: {
                  width: "10vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "gateEntryNo",
                label: "Gate Entry No",
                type: "text",
                allowNull: true,
                allowUpdate: true,
                props: {
                  width: "10vw",
                },
              },
              {
                name: "gateEntryDate",
                label: "Gate Entry Date",
                type: "date",
                constraints: [{ type: "DATE_RANGE", beforeNow: true }],
                allowNull: true,
                allowUpdate: true,
                props: {
                  width: "10vw",
                },
              },
            ],
          },
          {
            name: "sections",
            type: "formSection",
            childFields: [
              {
                name: "cancelled",
                label: "Cancelled",
                type: "checkbox",
                disabledForCreation: true,
                defaultValue: false,
                allowUpdate: true,
                disability: {
                  type: "IN",
                  field: "submit",
                  values: [true],
                },
              },
              {
                name: "submit",
                label: "Submit",
                type: "checkbox",
                defaultValue: false,
                allowUpdate: true,
                disability: {
                  type: "IN",
                  field: "cancelled",
                  values: [true],
                },
              },
            ],
          },
        ],
      },
      {
        name: "sections",
        type: "formSection",
        alignment: "column",
        props: {
          width: "21vw",
          spacing: "0.75vw",
          justify: "top",
        },
        childFields: [
          {
            name: "remarks",
            label: "Remarks",
            type: "text",
            allowUpdate: true,
            allowNull: true,
            props: {
              width: "25vw",
            },
          },
          {
            name: "fileInfos",
            label: "File Infos",
            type: "attachment",
            allowUpdate: true,
            allowNull: true,
            props: {
              width: "25vw",
              spacing: "0.75vw",
            },
          },
        ],
      },
    ],
  },
];

export const materialInwardColumns = [
  {
    name: "id",
    label: "MIR No",
    type: "text",
  },
  {
    name: "docDate",
    label: "MIR Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "docLocatorCode",
    label: "In Locator",
    type: "text",
  },
  {
    name: "docLocatorName",
    label: "Inward Locator Name",
    type: "text",
  },
  {
    name: "projectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "transactionType",
    label: "Transaction",
    allowNull: true,
    type: "text",
    format: (value) => {
      const transactionType = inTransactionTypeValues.find((item) => item.value === value);
      return transactionType ? transactionType.label : "Unknown";
    },
  },
  {
    name: "materialType",
    label: "Mat Type",
    allowNull: true,
    type: "text",
    format: (value) => {
      const materialType = materialTypeValues.find((item) => item.value === value);
      return materialType ? materialType.label : "Unknown";
    },
  },
  {
    name: "senderCompanyName",
    label: "Sender Company",
    type: "text",
  },
  {
    name: "senderLocatorName",
    label: "Sender Locator",
    type: "text",
  },
  {
    name: "basicValue",
    label: "Basic Value",
    type: "number",
  },
  {
    name: "totalValue",
    label: "Invoice Value",
    type: "number",
  },
  {
    name: "cancelled",
    label: "Cancelled",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "submit",
    label: "Submit",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

export const materialInwardSearchColumns = [
  {
    name: "mirId",
    label: "MIR No",
    type: "text",
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "docDate",
    label: "MIR Date",
    type: "date",
  },
  {
    name: "materialInwardMst.docLocatorCode",
    label: "In Locator",
    type: "text",
  },
  {
    name: "materialInwardMst.projectCode",
    label: "Project",
    type: "text",
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "transactionType",
    label: "Transaction",
    type: "picklist",
    values: inTransactionTypeValues,
  },
  // {
  //   name: "materialType",
  //   label: "Mat Type",
  //   type: "picklist",
  //   values: materialTypeValues,
  // },
  {
    name: "companyName",
    label: "Sender Company",
    defaultValue: null,
    fEntityType: "senderCompany",
    type: "text",
  },
  {
    name: "locatorName",
    label: "Sender Locator",
    defaultValue: null,
    fEntityType: "senderLocator",
    type: "text",
  },
  {
    name: "cancelled",
    label: "Cancelled",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.6,
    },
  },
  {
    name: "submit",
    label: "Submit",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.6,
    },
  },
];

export const materialInwardExcelColumns = [
  {
    name: "id",
    label: "MIR No",
    type: "text",
  },
  {
    name: "docDate",
    label: "MIR Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "docLocatorCode",
    label: "In Locator",
    type: "text",
  },
  {
    name: "docLocatorName",
    label: "Inward Locator Name",
    type: "text",
  },
  {
    name: "docStationName",
    label: "In Station",
    type: "text",
  },
  {
    name: "docStateName",
    label: "In State",
    type: "text",
  },
  {
    name: "projectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "virtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "docCompanyCode",
    label: "In Company",
    type: "text",
  },
  {
    name: "docCompanyName",
    label: "Inward Company Name",
    type: "text",
  },
  {
    name: "receiverDocType",
    label: "Receiver Doc Type",
    type: "text",
    format: (value) => receiverDocTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "receiverDocNo",
    label: "Receiver Doc No",
    type: "text",
  },
  {
    name: "receiverDocDate",
    label: "Receiver Doc Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "transactionType",
    label: "Transaction",
    type: "text",
    format: (value) => inTransactionTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "materialType",
    label: "Mat Type",
    type: "text",
    format: (value) => materialTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "senderLocatorCode",
    label: "Sender Locator",
    type: "text",
  },
  {
    name: "senderLocatorName",
    label: "Sender Locator Name",
    type: "text",
  },
  {
    name: "senderAddress1",
    label: "Address1",
    type: "text",
  },
  {
    name: "senderAddress2",
    label: "Address2",
    type: "text",
  },
  {
    name: "senderStationName",
    label: "Sender Station",
    type: "text",
  },
  {
    name: "senderStateName",
    label: "State/Country",
    type: "text",
  },
  {
    name: "senderCompanyCode",
    label: "Sender Company",
    type: "text",
  },
  {
    name: "senderCompanyName",
    label: "Sender Company Name",
    type: "text",
  },
  {
    name: "senderDocType",
    label: "Sender Doc Type",
    type: "text",
    format: (value) => senderDocTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "senderDocNo",
    label: "Sender Doc No",
    type: "text",
  },
  {
    name: "senderDocDate",
    label: "Sender Doc Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "basicValue",
    label: "Basic Value",
    type: "number",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "freightValue",
    label: "Freight Value",
    type: "number",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "iGSTValue",
    label: "iGST Value",
    type: "number",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "sGSTValue",
    label: "sGST Value",
    type: "number",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "cGSTValue",
    label: "cGST Value",
    type: "number",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalValue",
    label: "Invoice Value",
    type: "number",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "inventoryValue",
    label: "Inventory Value",
    type: "number",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalPkgs",
    label: "Total Pkgs",
    type: "number",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "transportMode",
    label: "Transport Mode",
    type: "text",
    format: (value) => transportModeValues.find((item) => item.value === value).label,
  },
  {
    name: "vendorCode",
    label: "Code",
    type: "text",
  },
  {
    name: "vendorName",
    label: "Transporter Name",
    type: "text",
  },
  {
    name: "consignmentNote",
    label: "Consignment Note",
    type: "text",
  },
  {
    name: "gateEntryNo",
    label: "Gate Entry No",
    type: "text",
  },
  {
    name: "gateEntryDate",
    label: "Gate Entry Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "remarks",
    label: "Remarks",
    type: "text",
  },
  {
    name: "cancelled",
    label: "Cancelled",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "submit",
    label: "Submit",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

export const MaterialInwardDetailExcelColumns = [
  {
    name: "mirId",
    label: "MIR No",
    type: "text",
  },
  {
    name: "docDate",
    label: "MIR Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "docLocatorCode",
    label: "In Locator",
    type: "text",
  },
  {
    name: "docLocatorName",
    label: "Inward Locator Name",
    type: "text",
  },
  {
    name: "docStationName",
    label: "In Station",
    type: "text",
  },
  {
    name: "docStateName",
    label: "In State",
    type: "text",
  },
  {
    name: "projectCode",
    label: "Project",
    type: "text",
  },
  {
    name: "projectName",
    label: "Project Name",
    type: "text",
  },
  {
    name: "projectType",
    label: "Project Type",
    type: "text",
    format: (value) => translatePicklistValue(value, projectTypeValues),
  },
  {
    name: "virtualLocator",
    label: "Virtual Locator",
    type: "text",
  },
  {
    name: "docCompanyCode",
    label: "In Company",
    type: "text",
  },
  {
    name: "docCompanyName",
    label: "Inward Company Name",
    type: "text",
  },
  {
    name: "receiverDocType",
    label: "Receiver Doc Type",
    type: "text",
    format: (value) => receiverDocTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "receiverDocNo",
    label: "Receiver Doc No",
    type: "text",
  },
  {
    name: "receiverDocDate",
    label: "Receiver Doc Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "transactionType",
    label: "Transaction",
    type: "text",
    format: (value) => inTransactionTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "materialType",
    label: "Mat Type",
    type: "text",
    format: (value) => materialTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "senderLocatorCode",
    label: "Sender Locator",
    type: "text",
  },
  {
    name: "senderLocatorName",
    label: "Sender Locator Name",
    type: "text",
  },
  {
    name: "senderAddress1",
    label: "Address1",
    type: "text",
  },
  {
    name: "senderAddress2",
    label: "Address2",
    type: "text",
  },
  {
    name: "senderStationName",
    label: "Sender Station",
    type: "text",
  },
  {
    name: "senderStateName",
    label: "State/Country",
    type: "text",
  },
  {
    name: "senderCompanyCode",
    label: "Sender Company",
    type: "text",
  },
  {
    name: "senderCompanyName",
    label: "Sender Company Name",
    type: "text",
  },
  {
    name: "senderDocType",
    label: "Sender Doc Type",
    type: "text",
    format: (value) => senderDocTypeValues.find((item) => item.value === value).label,
  },
  {
    name: "senderDocNo",
    label: "Sender Doc No",
    type: "text",
  },
  {
    name: "senderDocDate",
    label: "Sender Doc Date",
    type: "date",
    format: (value) => formatDate(value),
  },
  {
    name: "basicValue",
    label: "Basic Value",
    type: "number",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "inventoryValue",
    label: "Inventory Value",
    type: "number",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "id",
    label: "Row Id",
    type: "text",
  },
  {
    name: "itemName",
    label: "Item Name",
    type: "text",
  },
  {
    name: "itemId",
    label: "Item Id",
    type: "text",
  },
  {
    name: "itemCustCode",
    label: "Part Code",
    type: "text",
  },
  {
    name: "uom",
    label: "Unit",
    type: "text",
  },
  {
    name: "totalQuantity",
    label: "Inward Quantity",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "basicAmount",
    label: "Basic Amount",
    type: "text",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "rate",
    label: "Rate",
    type: "number",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "totalAmount",
    label: "Total Amount",
    type: "number",
    format: (value) => (value ? parseFloat(value) : 0),
  },
  {
    name: "cancelled",
    label: "Cancelled",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "submit",
    label: "Submit",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];
