import { companyTypeValues } from "./company";

export const companyGstFields = [
  {
    name: "companyName",
    label: "Company Name",
    type: "lookup",
    lookupType: "company",
    lookupCategory: "company",
    lookupFilter: {
      type: "AND",
      filters: [
        { type: "IN", field: "gstFlg", values: [true] },
        { type: "IN", field: "foreignFlg", values: [false] },
        {
          type: "OR",
          filters: [
            { type: "IN", field: "ownerFlg", values: [true] },
            { type: "IN", field: "partnerFlg", values: [true] },
            { type: "IN", field: "supplierFlg", values: [true] },
          ],
        },
      ],
    },
    props: {
      width: "25vw",
    },
    dependentFields: [
      {
        lookupFieldName: "id",
        fieldName: "companyCode",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "panNo",
        fieldName: "panNo",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "ownerFlg",
        fieldName: "ownerFlg",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "partnerFlg",
        fieldName: "partnerFlg",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "contractorFlg",
        fieldName: "",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "supplierFlg",
        fieldName: "supplierFlg",
      },
      {
        additionalFieldName: "details",
        lookupFieldName: "gstFlg",
        fieldName: "gstFlg",
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "ownerFlg",
        label: "Project Owner",
        type: "checkbox",
        autoFilled: true,
        defaultValue: false,
      },
      {
        name: "partnerFlg",
        label: "Partner",
        type: "checkbox",
        autoFilled: true,
        defaultValue: false,
      },
      {
        name: "supplierFlg",
        label: "Supplier",
        type: "checkbox",
        autoFilled: true,
        defaultValue: false,
      },
    ],
  },
  {
    name: "section1",
    type: "formSection",
    childFields: [
      {
        name: "gstNo",
        label: "GST Number",
        type: "text",
        allowNull: false,
        props: {
          width: "17vw",
        },
      },
      {
        name: "companyCode",
        label: "Company Code",
        type: "text",
        autoFilled: true,
        props: {
          width: "7vw",
        },
      },
    ],
  },
  {
    name: "address1",
    label: "Address1",
    type: "text",
    allowUpdate: true,
    defaultValue: "",
    props: {
      width: "25vw",
    },
  },
  {
    name: "address2",
    label: "Address2",
    type: "text",
    allowUpdate: true,
    allowNull: true,
    defaultValue: "",
    props: {
      width: "25vw",
    },
  },
  {
    name: "section2",
    type: "formSection",
    childFields: [
      {
        name: "stationName",
        label: "Station Name",
        type: "lookup",
        allowUpdate: true,
        props: {
          width: "17vw",
        },
        lookupType: "station",
        lookupFilter: {
          type: "EXISTS",
          field: "gstCode",
        },
        dependentFields: [
          {
            lookupFieldName: "id",
            fieldName: "stationCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "stateCode",
            fieldName: "stateCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "gstCode",
            fieldName: "gstCode",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "stateName",
            fieldName: "stateName",
          },
          {
            additionalFieldName: "details",
            lookupFieldName: "country",
            fieldName: "country",
          },
        ],
      },
      {
        name: "stationCode",
        label: "Station Code",
        type: "text",
        props: {
          width: "7vw",
        },
        autoFilled: true,
      },
    ],
  },
  {
    name: "section2",
    type: "formSection",
    childFields: [
      {
        name: "gstCode",
        label: "GST Code",
        props: {
          width: "7vw",
        },
        type: "text",
        autoFilled: true,
        defaultValue: "",
      },
      {
        name: "stateName",
        label: "State Name",
        props: {
          width: "17vw",
        },
        type: "text",
        autoFilled: true,
        defaultValue: "",
      },
    ],
  },
  {
    name: "section2",
    type: "formSection",
    childFields: [
      {
        name: "country",
        label: "Country",
        type: "text",
        autoFilled: true,
        props: {
          width: "17vw",
        },
      },
      {
        name: "id",
        label: "GST Id",
        type: "text",
        autoFilled: true,
        allowNull: true,
        props: {
          width: "7vw",
        },
      },
    ],
  },
  {
    name: "section2",
    type: "formSection",
    childFields: [
      {
        name: "gstFlg",
        label: "GST Regd.",
        type: "checkbox",
        autoFilled: true,
        defaultValue: false,
      },
      {
        name: "active",
        label: "Active",
        type: "checkbox",
        allowUpdate: true,
        defaultValue: false,
      },
    ],
  },
];

export const companyGstColumns = [
  {
    name: "companyCode",
    label: "Code",
    type: "text",
  },
  {
    name: "companyName",
    label: "Company Name",
    type: "text",
  },
  {
    name: "stationName",
    label: "Station",
    type: "text",
  },
  {
    name: "stateName",
    label: "State",
    type: "text",
  },
  {
    name: "gstNo",
    label: "GST Number",
    type: "text",
  },
  {
    name: "ownerFlg",
    label: "Project Owner",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "partnerFlg",
    label: "Partner",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "supplierFlg",
    label: "Supplier",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  // {
  //   name: "address1",
  //   label: "Address1",
  //   type: "text",
  // },
  {
    name: "active",
    label: "Active",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
];

export const companyGstExcelColumns = [
  {
    name: "companyCode",
    label: "Code",
    type: "text",
  },
  {
    name: "companyName",
    label: "Company Name",
    type: "text",
  },
  {
    name: "companyType",
    label: "Company Type",
    type: "text",
    format: (value) => {
      const companyType = companyTypeValues.find((item) => item.value === value);
      return companyType ? companyType.label : "Unknown";
    },
  },
  {
    name: "gstNo",
    label: "GST Number",
    type: "text",
  },
  {
    name: "ownerFlg",
    label: "Project Owner",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "partnerFlg",
    label: "Partner",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "supplierFlg",
    label: "Supplier",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "address1",
    label: "Address1",
    type: "text",
  },
  {
    name: "address2",
    label: "Address2",
    type: "text",
  },
  {
    name: "stationName",
    label: "Station",
    type: "text",
  },
  {
    name: "stateName",
    label: "State",
    type: "text",
  },
  {
    name: "gstCode",
    label: "GST Code",
    type: "text",
  },
  {
    name: "active",
    label: "Active",
    type: "text",
    format: (value) => (value ? "True" : "False"),
  },
  {
    name: "id",
    label: "GST Id",
    type: "text",
  },
];

export const companyGstSearchColumns = [
  {
    name: "companyGst.companyCode",
    label: "Code",
    type: "text",
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "companyName",
    label: "Company Name",
    type: "text",
    props: {
      widthFactor: 1.4,
    },
  },
  {
    name: "stationName",
    label: "Station",
    type: "text",
  },
  {
    name: "stateName",
    label: "State",
    type: "text",
  },
  {
    name: "gstNo",
    label: "GST Number",
    type: "text",
  },
  // {
  //   name: "companyGst.address1",
  //   label: "Address1",
  //   type: "text",
  // },
  {
    name: "ownerFlg",
    label: "Owner",
    type: "picklist",
    fEntityType: "company",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "partnerFlg",
    label: "Partner",
    type: "picklist",
    fEntityType: "company",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "supplierFlg",
    label: "Supplier",
    type: "picklist",
    fEntityType: "company",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.8,
    },
  },
  {
    name: "active",
    label: "Active",
    type: "picklist",
    defaultValue: "",
    values: [
      { value: true, label: "True" },
      { value: false, label: "False" },
    ],
    props: {
      widthFactor: 0.6,
    },
  },
];
